<template>
    <div class="change--current--date">
        <span class="iconify" 
        data-icon="bx:calendar" 
        style="color: #0075ff;" 
        data-width="30" 
        data-height="30" 
        @click="openDataPicker">
        </span>
        <input class="datapicker--button" type="button" ref="input">
    </div>
</template>

<script>
import AirDatepicker from 'air-datepicker'
import 'air-datepicker/air-datepicker.css';
import {bus} from '../EventBus.js'
import localeEn from 'air-datepicker/locale/en';

export default {
    name: 'ChangeDate',
    props:{
        currentDay: Date
    },
    data() {
        return {
            dataPicker : {},
            dataPickerIsShow: false
        }
    },
    mounted(){
        this.dataPicker = new AirDatepicker(this.$refs['input'], {
            isMobile: true,
            autoClose: true,
            locale: localeEn,
            selectedDates: [this.currentDay],
            onSelect({date}) {
                bus.$emit('calendar', 'select-date', {date: date, isSetupToday: true})
            }
        });
        console.log('first', this.dataPicker)
    },
    methods: {
        openDataPicker(){
            
            if (this.dataPickerIsShow){
                this.dataPicker.hide()
            } else {
                this.dataPicker.show()
            }
            this.dataPickerIsShow = !this.dataPickerIsShow
        }
    },
}
</script>

<style scoped>
.change--current--date{
    position: relative;
    height: 30px;
    width: 30px;
}
.datapicker--button{
    height: 30px;
    width: 30px;
}
input{
    opacity: 0;
    position:absolute;
    top: 0;
    left: 0;
}

</style>