<template>
    <div class="change-step"  @click="updateMovementStep">            
        <div class="icon" v-if="currentValue.icon">
            <img :src="require('@/assets/' + currentValue.icon)" height="22" width="22">
        </div>
        <div class="change-step__title" v-if="currentValue.title">
            {{currentValue.title}}
        </div>
    </div>
</template>

<script>
import { TABLE_MOVEMENT_ACTION_COPY, TABLE_MOVEMENT_ACTION_REPLACE} from '../../../scripts/tableMovementAction'

const MOVEMENT_ACTIONS = [
	{
		value: TABLE_MOVEMENT_ACTION_REPLACE,
		title: "Move",
        icon: "open-with-rounded.svg"
	},
    {
        value: TABLE_MOVEMENT_ACTION_COPY,
        title: "Copy",
        icon: "file-copy-outline-rounded-blue.svg"
    }
]

export default {
    name: 'ChangeMovementAction',
    data() {
        return {
            currentValue: MOVEMENT_ACTIONS[0]
        }
    },
    methods: {
        updateMovementStep(){
            let newAction;

            let currentActionIndex = MOVEMENT_ACTIONS.indexOf(this.currentValue)
            if (currentActionIndex == MOVEMENT_ACTIONS.length - 1) {
                newAction = MOVEMENT_ACTIONS[0]
            } else {
                newAction = MOVEMENT_ACTIONS[currentActionIndex + 1]
            }

            this.currentValue = newAction
            this.$store.dispatch('update-table-movement-action', {
                action: this.currentValue.value
            })
        },
    },
}
</script>

<style scoped>
.change-step{
    height:30px;
    width: 78px;

    outline: 1px solid #0075ff;
    border-radius: 8px;
    
    font-size: 12px;

    display: grid;
    grid-template-columns: 30px calc(100% - 30px);

    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.change-step > * {
    height: inherit;
}

.change-step > .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
}

.change-step > .change-step__title {
    display: flex;
    align-items: center;
}
</style>