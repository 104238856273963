<template>
    <div class="compare__calendars" @click="openCalendarList">
        <span class="iconify" data-icon="carbon:overlay" data-width="28" data-height="28"></span>
    </div>
</template>

<script>
import Vue from 'vue'
import { globalBus } from '../../../GlobalEventBus'
import CMS from '../../../service/cms/service'
import { bus } from '../EventBus'
import CalendarContextTitle from '../../compare-calendar-list/CalendarContextTitle.vue'

export default {
    name: 'CompareCalendar',
    methods: {

        setupComparableCalendar(calendarUid){
            let week = this.$store.getters.tableHeader.week.map((item) => item.date)

            console.log(`compare calendars info:
                comparable calendar ${calendarUid}
                from                ${week[0]}
                to                  ${week[week.length - 1]}`)

            this.$store.dispatch('update-compare-slots', {
                from: week[0],
                to: week[week.length - 1],
                uid: calendarUid,
            }).then(() => {
                this.$store.dispatch('update-table-mode', {isCompare: true})
                bus.$emit('table', 'setup-compare-mode')
                this.openCompareCalendarHeader(calendarUid)
            })
        },

        openCompareCalendarHeader(calendarUid){
            CMS.calendar.getByUid(calendarUid).then((response) => {
                if (response.err){
                    throw new Error(response.err)
                }

                let ComponentClass = Vue.extend(CalendarContextTitle)
                let instance = new ComponentClass({
                    propsData: { title: response.title }
                })
                let studio = document.getElementById('studio')
                console.log('studio', studio)
                instance.$mount() // pass nothing
                studio.appendChild(instance.$el)
            })
        },

        openCalendarList(){
            globalBus.$emit('page-header', 'open-personal-calendar-list', {
                title: "Select the calendar to compare",
                selector: this.setupComparableCalendar,
                excludeCurrent: true,
                excludeCreate: true,
            })
        }
    },
}
</script>

<style scoped>
.compare__calendars{
    color: #0075ff;
    text-decoration-line: underline;
    height: 30px;
    width: 30px;
}
</style>