<template>
    <div id="calendar">
        <Filt 
            v-if="hasFilter" 
            :currentDay="tableHeader.selectedDate"
            :role="role"
        />
        <div class="calendar__content"
        v-touch:swipe.right="swipeRight"
        v-touch:swipe.left="swipeLeft"
        @touchstart="touchStartHandler"
        @touchmove="touchMoveHandler"
        @touchend="touchEndHandler">
            <!-- {{ scale }}
            {{ error }} -->
            <!-- {{ tableHeader.getAllWeek() }} -->
            <!-- {{ tableHeader.getAllWeek().map((headerItem) => headerItem.scale) }} -->
            
            <!-- <div>scaleArray{{ scaleArray }}</div>
            <div>supportDates{{ tableHeader.supportDates }}</div> -->
            <Header :currentDay="tableHeader.selectedDate"/>
            <div id="calendar-columns" >
                <Hours/>
                <Table
                    :swipeStep="swipeStep"
                    :currentDay="tableHeader.selectedDate"
                    :classification="classification"
                    :tableMode="mode"
                    :role="role"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Header from './header/Header.vue'
import Hours from './hours/Hours.vue'
import Table from './table/Table.vue'
import Filt from './filter/Filter.vue'

import { getCurrentWeek, isEqualDate, nextDate, dateDifferenceInDays } from '../../scripts/date'
import CalendarHeader from '../../scripts/calendarHeader'

import {bus} from './EventBus.js'
import Vue from 'vue'
import router from '../../router'

import YYYYMMDD from '../../scripts/date'
import CalendarHeaderItem from '../../scripts/calendarHeaderItem'

const MAX_SCALE = 4.5

export default {
    name: 'Calendar',
    components:{
        Header,
        Hours,
        Table,
        Filt,
    },
    props: {
        holidays: Array,
        specialDays: Array,
        classification: Object,
        hasFilter: Boolean,
        role: String,

    },
    computed: {
        mode(){
            return this.$store.getters.tableMode
        },
        tableHeader(){
            return this.$store.getters.tableHeader
        },
        columnCount(){
            return this.tableHeader.columnCount
        }
    },
    data(){
        return {
            swipeStep: 1,
            
            swipeEnabled: true,
            initialDistance: -1,
            initialScale: -1,
            scale: -1,
            scaleArray: [],
            error: null,

            scaleIsAllowed: true,
        }
    },
    created() {
        bus.$on('calendar', 'select-date', this.selectDate)

        bus.$on('calendar', 'set-column-count', ({columnCount})=> {
            this.tableHeader.updateColumnCount(columnCount)
            // bus.$emit('calendar', 'set-current-week', {})
            this.updateURI()
        })

        bus.$on('calendar', 'allow-scale', ({allow}) => this.scaleIsAllowed = allow)

        // Update table header

        bus.$on('calendar', 'set-current-week', ({isRender}) => {
            this.$store.dispatch('update-table-week', {
                selectedDate: this.tableHeader.selectedDate,
            })
            .then(() => {
                if (!isRender) this.updateURI()
                this.tableHeader.updateSlots()
            })
        })

        if (this.tableHeader){
            this.tableHeader.updateSlots()
            return 
        }

        let columnCount
        let initColumnCount = this.$route.query.w
        if (initColumnCount)
            columnCount = Number(initColumnCount)
        else   
            columnCount = 7

        let date
        let initDate = this.$route.query.dt
        if (initDate)
            date = new Date(initDate)
        else 
            date = new Date()

        let header = new CalendarHeader(date, columnCount)
        this.$store.dispatch('set-table-header', header)

        // this.scale = 1.1
        // this.updateSupportDates()
        // this.scaleColumns()

    },
    mounted(){
        this.$store.dispatch('update-ceil-size', {columnCount: this.columnCount})
    },
    beforeDestroy() {
        bus.$off('calendar')
    },
    methods: {
        touchMoveHandler(ev){
            if (!this.scaleIsAllowed)
                return
            const touches = ev.touches;
            let isPinnedDate = this.tableHeader.isPinnedDate(this.tableHeader.selectedDate)
            if (touches.length == 2 && this.initialDistance != null && !isPinnedDate) {

                const currentDistance = Math.hypot(
                    touches[0].clientX - touches[1].clientX,
                    touches[0].clientY - touches[1].clientY
                );
                this.scale = (currentDistance / this.initialDistance) * this.initialScale
                if (this.scale > 1)
                {
                        
                    this.updateSupportDates()
                    this.scaleColumns()
                }
            }
        },
        touchStartHandler(ev){
            if (!this.scaleIsAllowed)
                return

            const touches = ev.touches;
            let isPinnedDate = this.tableHeader.isPinnedDate(this.tableHeader.selectedDate)
            if (touches.length == 2 && !isPinnedDate) {
                this.swipeEnabled = false
                this.initialDistance = Math.hypot(
                    touches[0].clientX - touches[1].clientX,
                    touches[0].clientY - touches[1].clientY
                );
                this.initialScale = 1;
            }
        },
        scaleHandler(){

            if (this.scale > 2.4){
                const columnCounts = [7,5,3]
                let currentCountIndex = columnCounts.findIndex((el) => el == this.tableHeader.columnCount)
                if (currentCountIndex != 2)
                {
                    this.scale = MAX_SCALE
                    this.scaleColumns()
                    setTimeout(() => {
                        this.tableHeader.updateColumnCount(columnCounts[currentCountIndex + 1])
                        this.updateURI()
                    }, 300)
                }
            }
            else if (this.scale < 0.7 && this.scale > 0){
                const columnCounts = [3,5,7]
                let currentCountIndex = columnCounts.findIndex((el) => el == this.columnCount)
                if (currentCountIndex != 2)
                {
                    this.tableHeader.updateColumnCount(columnCounts[currentCountIndex + 1])
                    this.updateURI()
                }
            }
            else{
                this.tableHeader.updateColumnCount(this.tableHeader.columnCount)
            }
        },
        touchEndHandler(ev){
            if (!this.scaleIsAllowed)
                return

            this.initialDistance = -1;
            this.initialScale = -1;
            if (this.scale != -1)
                this.scaleHandler()

            this.scale = -1;
            setTimeout(() => {
                // this.tableHeader.supportDates = []
                this.swipeEnabled = true
            }, 100)
        },
        selectDate({date, isSetupToday}){
            if (!date)
                throw new Error("calendar : no date to select")
            let isPinnedDate = this.tableHeader.isPinnedDate(date)
            if ((!this.tableHeader.includes(date) || isSetupToday ) && !isPinnedDate){
                this.$store.dispatch('update-table-week', {
                    selectedDate: date,
                })
                .then(this.updateURI)
            } else {
                Vue.set(this.tableHeader, "selectedDate", date)
                this.updateURI()
            }
        },
        scaleColumns()
        {
            try
            {
                let isZoomUp = this.scale > 1
                if (isZoomUp && this.tableHeader.columnCount == 3)
                    return
                if (!isZoomUp && this.tableHeader.columnCount == 7)
                    return
                let week = this.tableHeader.getAllWeek()
                let selectedDate = this.tableHeader.selectedDate
                let futureColumnCount = this.tableHeader.columnCount
                if (isZoomUp)
                    futureColumnCount -= 2
                else
                    futureColumnCount += 2
                let zoomPercent
                if (isZoomUp)
                    zoomPercent = this.scale / MAX_SCALE
                else 
                    zoomPercent = 1 - this.scale

                let futureWeek = getCurrentWeek(selectedDate, futureColumnCount)

                // Items that will be removed after zoom
                let extraHeaderItems = this.tableHeader.getAllWeek()
                    .filter(headerItem => {
                        return futureWeek.find(date => {
                            return isEqualDate(date, headerItem.date)
                        }) == undefined
                    })
                    extraHeaderItems.forEach(headerItem =>{ 
                        if (headerItem.scale == 0)
                        return
                    headerItem.scale = Number((1 - zoomPercent).toFixed(2))
                });
                
                // Items that will stay after zoom
                let newHeaderItems = this.tableHeader.getAllWeek()
                    .filter(headerItem => {
                        // this.scaleArray = headerItem.date

                        let isInFuture = futureWeek.find(date => {
                            this.scaleArray = {
                                date,
                                header: headerItem,
                            }
                            return isEqualDate(date, headerItem.date)
                        }) != undefined
                        let isSupport = this.tableHeader.isSupportDate(headerItem.date)
                        let isZeroScale = headerItem.scale == 0
                        return isInFuture && (isSupport || isZeroScale)
                    })
                
                    
                let stateHeaderItems = this.tableHeader.getAllWeek()
                    .filter(headerItem => {
                        let isInFuture = futureWeek.find(date => isEqualDate(date, headerItem.date)) != undefined
                        let isSupport = this.tableHeader.isSupportDate(headerItem.date)
                        let isZeroScale = headerItem.scale == 0
                        return isInFuture && !isSupport && !isZeroScale
                    })

                stateHeaderItems.forEach(headerItem => {
                    let newScale = zoomPercent * extraHeaderItems.length / (week.length - extraHeaderItems.length)
                    if (newScale == 0 && newHeaderItems.length > 0)
                        newScale = zoomPercent
                    if (isZoomUp)
                        headerItem.scale = 1 + newScale
                    else
                        headerItem.scale = 1 - newScale
                    headerItem.scale = Number(headerItem.scale.toFixed(2))
                })
        
                newHeaderItems.forEach(headerItem => {
                    let newScale = zoomPercent * extraHeaderItems.length / (week.length - extraHeaderItems.length)
                    if (newScale == 0 && newHeaderItems.length > 0)
                        newScale = zoomPercent
                    if (isZoomUp)
                        headerItem.scale = 1 + newScale
                    else
                        headerItem.scale = newScale
                    headerItem.scale = Number(headerItem.scale.toFixed(2))
                })
                // this.scaleArray = this.tableHeader.getAllWeek().map(headerItem => headerItem.scale)
            }
            catch (err)
            {
                this.error = err
            }
            // // Items that will be added after zoom

            // this.$store.dispatch('set-calendar-column-scale', columnsScale)
        },
        swipeRight(){
            if (!this.swipeEnabled || this.mode.isChangeColumn)
                return

            this.$store.dispatch('table-swipe-right', {count: 1})
            .then((week) => {
                // Update current week
                this.tableHeader.updateSlots()
                this.updateURI()
            })
        },
        swipeLeft(){
            if (!this.swipeEnabled || this.mode.isChangeColumn)
                return

            this.$store.dispatch('table-swipe-left', {count: 1})
            .then(() => {
                this.tableHeader.updateSlots()
                this.updateURI()
            })
        },

        updateSupportDates(){

            let isZoomUp = this.scale > 1
            let futureColumnCount = this.tableHeader.columnCount

            if (this.tableHeader.columnCount != 3 && isZoomUp)
                futureColumnCount -= 2
            else if (this.tableHeader.columnCount != 7 && !isZoomUp)
                futureColumnCount += 2

            this.tableHeader.updateSupportDates(futureColumnCount)
        },

        updateURI(){
            router.replace({
                name:'calendar', 
                params: this.$route.params, 
                query: {
                    w: this.columnCount, 
                    dt: YYYYMMDD(this.tableHeader.selectedDate)
                } 
            }).catch(()=>{})

            this.$store.dispatch('set-calendar-props', {
                selectedDate: YYYYMMDD(this.tableHeader.selectedDate),
                columnCount: this.columnCount,
            })

            this.$nextTick().then( () => {
                this.$store.dispatch('update-ceil-size', {columnCount: this.columnCount})
            })
        },
    },
}
</script>

<style scoped>

#calendar-columns{
    display: grid;
    grid-template-columns: 30px calc(100% - 30px);
}

</style>