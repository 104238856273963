<template>
    <div class="calendar_context_title" >
        <div class="calendar__title">
            <div class="color">
        </div>
            {{title}}
        </div>

        <div class="close" @click="close()">
            <span 
                class="iconify" 
                data-icon="clarity:window-close-line" 
                data-width="26"
                style="color: red;"  
                
                data-height="26">
            </span>
        </div>
    </div>
</template>

<script>
import Item from './Item.vue'
import { bus } from '../calendar/EventBus';

export default {
    name: 'CalendarContextTitle',
    components:{
        Item
    },
    props: {
        title: String,
    },
    data() {
        return {
            showModal:true
        }
    },
    methods: {
        close () {
            bus.$emit('table', 'disable-compare-mode')
            // destroy the vue listeners, etc
            this.$destroy();
            // remove the element from the DOM
            this.$el.parentNode.removeChild(this.$el);
        }
    },
}
</script>

<style scoped>
.calendar_context_title{
    position:fixed ;
    height: 40px;
    top: 0;
    width: 100%;
    background: white;
    border-bottom: 1px solid grey;
    z-index: 1000;

    animation-name: title;
    animation-duration: 0.75s;
    animation-timing-function: cubic-bezier(0.74, -0.2, 0.31, 0.97);

    -webkit-box-shadow: 0px 6px 8px 0px rgba(43, 57, 67, 0.15);
    -moz-box-shadow: 0px 6px 8px 0px rgba(43, 57, 67, 0.15);
    box-shadow: 0px 6px 8px 0px rgba(43, 57, 67, 0.15);
}

.all__overlay{
    height: 100%;
}

.close{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width:40px;
    align-items: center;
    justify-content: center;
    display: flex;
}

@keyframes title {
    0% {
        opacity: 0.3;
        top: 200px;
    }
    100%{
        opacity: 1;
        top: 0;
    }   
}

.color{
    margin-right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    width: 22px;
    border-radius: 6px;
    background: #99d6f9;
}

.calendar__title{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
</style>