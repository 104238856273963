<script>
export default {
    name: 'AlertMixin',
    methods: {
        executeAlert(text){
            this.$swal({
                title: 'Ошибка',
                text: text,
                position: 'top-end',
                showConfirmButton: false,
                padding: "5px 15px",
                width: "100%",
                timer: 3000,
                icon: 'error',
                toast: true,
                heightAuto: false,
            });
        }
    }
}
</script>