<template>
    <div class="change-step"  @click="updateMovementStep">            
        {{currentStep}} min.
    </div>
</template>

<script>
import {bus} from '../EventBus.js'

const STEP_VALUES = [
    1, 
    5,
    10,
]

export default {
    name: 'ChangeMovementStep',
    data() {
        return {
            currentStep: STEP_VALUES[1],
        }
    },
    created(){
        let calendarUid = this.$route.params.calendarUid
        let savedMovementStep = localStorage.getItem(`hora-${calendarUid}-slot-movement-step`)
        if (!savedMovementStep || 
            Number(savedMovementStep) == NaN ||
            !STEP_VALUES.includes(Number(savedMovementStep)))
            return
        
        this.currentStep = Number(savedMovementStep)
        bus.$emit('table', 'set-movement-step', {
            step: this.currentStep
        })
    },
    methods: {
        updateMovementStep(){
            let newStep;

            let currentStepIndex = STEP_VALUES.indexOf(this.currentStep)
            if (currentStepIndex == STEP_VALUES.length - 1) {
                newStep = STEP_VALUES[0]
            } else {
                newStep = STEP_VALUES[currentStepIndex + 1]
            }

            this.currentStep = newStep
            bus.$emit('table', 'set-movement-step', {
                step: this.currentStep
            })

            let calendarUid = this.$route.params.calendarUid
            localStorage.setItem(`hora-${calendarUid}-slot-movement-step`, `${this.currentStep}`)
        },
    },
}
</script>

<style scoped>
.change-step{
    height:30px;
    width: 52px;

    outline: 1px solid #0075ff;
    border-radius: 8px;
    
    font-size: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}


</style>