<template>
<span id="table">
    <div id="time-borders" class="working-time-borders" >
        <div class="hour-border" :style="{'flex' : `0 0 ${workTimeTopHeight}` }"></div>
        <div class="working-time" v-bind:style="{
            height: `calc(100% - ${workTimeTopHeight} - ${workTimeBotHeight} - 60px)`,
        }">

        </div>
        <div class="hour-border" :style="{'flex' : `0 0 ${workTimeBotHeight}` }"></div>
    </div> 
    <div id="calendar-table" class="calendar__table" 
        v-if="tableHeader && tableHeader.week.length > 0">
        
        <!-- Supported ceils for slots with unsupported work time -->
        <SupportedWorkTime position="top"/>   
        <CreateSlot v-if="testEvent" :testEvent="testEvent" :replaceableSlot="replaceableSlot"/>

        <!-- Main columns -->
        <div class="column__cont" v-if="workSpace">
            <Column v-for="columnItem in tableHeader.leftSide()"
                :key="`table-column-${columnItem.date.toLocaleDateString()}`"
                :columnItem="columnItem"
                :border="{
                    top: workSpace.top.projection(columnItem.date),
                    bot: workSpace.bot.projection(columnItem.date)
                }"
                :slotIsChangePlace="slotIsChangePlace"
                :tableMode="tableMode"
                :classification="classification"
                :movementStep="movementStep"
                :replaceableSlot="replaceableSlot"
            />
            <keep-alive>
                <Column :columnItem="tableSelectedItem()"
                    :border="{
                        top: workSpace.top.projection(tableSelectedItem().date),
                        bot: workSpace.bot.projection(tableSelectedItem().date)
                    }"
                    :slotIsChangePlace="slotIsChangePlace"
                    :tableMode="tableMode"
                    :classification="classification"
                    :movementStep="movementStep"
                    :replaceableSlot="replaceableSlot"
                    isSelected
                />
            </keep-alive>
            <Column v-for="columnItem in tableHeader.rightSide()"
                :key="`table-column-${columnItem.date.toLocaleDateString()}`"
                :columnItem="columnItem"
                :border="{
                    top: workSpace.top.projection(columnItem.date),
                    bot: workSpace.bot.projection(columnItem.date)
                }"
                :slotIsChangePlace="slotIsChangePlace"
                :tableMode="tableMode"
                :classification="classification"
                :movementStep="movementStep"
                :replaceableSlot="replaceableSlot"
            />
        </div>
        <!-- Supported ceils for slots with unsupported work time -->
        <SupportedWorkTime position="bottom"/>  
        <div class="trash_slot_bucket_cont">
            <transition name="garbage">
                <div class="bucket__border" 
                v-if="(tableMode.isPlanning && tableMode.isChangePlace) || testEvent" 
                v-bind:class="{
                    preparation : isSlotDeletePreparation,
                }">
                    <span class="iconify" data-icon="uil:trash-alt" data-width="32" data-height="32"></span>
                </div>
            </transition>
        </div>
    </div>
    
    
    <div class="nearest_planning_date"
        v-if="tableMode.isPlanning && isPastCalendar">
        <div class="date">Nearest planning date: {{ nearestPlanningDate.toLocaleDateString() }}</div>
        <div class="move__button" @click="moveToNearestPlanningDate">Move</div>
    </div>
</span> 
</template>

<script>
import Vue from 'vue'
import Column from './Column.vue'
import {bus} from '../EventBus.js'
import CreateSlot from './CreateSlot.vue'
import TimePeriod from '../../../scripts/timePeriod.js'
import DateTimeMixin from './mixins/DateTimeMixin.vue'
import Slot from        '../../../scripts/slot'
import CMS from         '../../../service/cms/service'
import {convertTZ, dateIsPast, UTCString} from '../../../scripts/date'
import { TABLE_MOVEMENT_ACTION_COPY, TABLE_MOVEMENT_ACTION_REPLACE } from '../../../scripts/tableMovementAction'

export default {
    name: "Table",
    props: {
        currentDay: Date,
        swipeStep: Number,
        classification: Object,
        tableMode: {
            type: Object,
            default: () => {
                return {
                    isPlanning: false,
                    isCompare: false,
                }
            }
        },
        role: String,
    },
    mixins: [
        DateTimeMixin
    ],
    components:{
        Column,
        CreateSlot,
        SupportedWorkTime: () => import('./support-work-time/SupportedWorkTime.vue'),
        // ChoiceReplaseOrCopySlot: () => import('./ChoiceReplaseOrCopySlot.vue'),
    },
    created() {

        // Slot events
        bus.$on('table', 'open-slot-date-modal', ({date, index, futureColumnNumber}) => {
            this.updateSlotInfo = {date, index, futureColumnNumber}
            this.slotIsChangePlace = true
            this.$store.dispatch('update-slot-styles', {
                index, 
                isChangeColumn: true,
            })
        })

        bus.$on('table', 'slot-movement-action', this.slotMovementHandler)

        bus.$on('table', 'update-slot-time', this.updateSlotTime)
        bus.$on('table', 'update-slot-date', this.updateSlotDate)
        bus.$on('table', 'create-slot-copy', this.createSlotCopy)

        bus.$on('table', 'setup-compare-mode', this.setupCompareMode)
        bus.$on('table', 'disable-compare-mode', this.disableCompareMode)
    
        bus.$on('table', 'update-slot-delete-preparation', ({isDelete}) => this.isSlotDeletePreparation=isDelete)

        // Slot list events
        bus.$on('table', 'setup-slot-list', this.setupTrainings) 

        // Table events
        bus.$on('table', 'set-movement-step', info => this.movementStep = info.step)
        bus.$on('table', 'update-replaceable-slot', (slot) => {
            this.replaceableSlot = slot
        })
    },
    beforeDestroy() {
        // bus.$off('table')
        this.$store.dispatch('clear table')
    },
    computed: {
        tableHeader() {
            return this.$store.getters.tableHeader
        },
        workTimeTopHeight(){
            if (!this.workStart) return
            return this.workStart.minute / 60 * 54 + 'px'
        },
        workTimeBotHeight(){
            if (!this.workEnd) return
            if (this.workEnd.minute == 0) return '0px'

            return (1 - this.workEnd.minute / 60) * 54  + 'px'
        },
        columnCount(){
            return this.$store.getters.tableHeader.columnCount
        },
        workStart(){
            return this.$store.getters.calendarWorkStart
        },
        workEnd(){
            return this.$store.getters.calendarWorkEnd
        },


        workSpace(){

            let start = this.$store.getters.calendarWorkStart
            let end = this.$store.getters.calendarWorkEnd
            if (!start || !end)
                return null
            /**
             * Limit is a final value of date on top or bottom
             * Border is an area of not-working period
             */

            let limitTop = new Date()
            limitTop.setHours(start.hour, 0)

            let limitBot = new Date()
            limitBot.setHours(end.hour,end.minute)

            let minute = end.minute != 0? 59 - end.minute : 0
            let botBorder = new TimePeriod(limitBot, -1, minute)
            let topBorder = new TimePeriod(limitTop, -1, start.minute)
            topBorder.shift(-1)
            
            return {
                top: topBorder,
                bot: botBorder,
            }
        },
        nearestPlanningDate(){
            let nearestDate = new Date()
            let workEnd = this.workEnd
            if (workEnd.hour == 24) {
                workEnd = { 
                    hour: 23,
                    minute: 59,
                }
            }

            let isNotWorkingTime = nearestDate.getHours() > workEnd.hour || 
            (nearestDate.getMinutes() > workEnd.minute && nearestDate.getHours() >= workEnd.hour) 

            if (isNotWorkingTime){
                nearestDate.setDate(nearestDate.getDate() + 1)
            }

            return nearestDate
        },

        isPastCalendar(){
            let workEnd = this.workEnd
            if (workEnd.hour == 24) {
                workEnd = { 
                    hour: 23,
                    minute: 59,
                }
            }

            let result = true
            this.tableHeader.week.forEach((item) => {
                let endOfDay = new Date(item.date)
                endOfDay.setHours(workEnd.hour, workEnd.minute)
                result = result && dateIsPast(endOfDay)
            })

            return result
        },
        testEvent(){
			return this.$store.getters.tableCreateEvent
		},
    },
    data(){
        return {
            isSlotDeletePreparation: false,
            slotIsChangePlace: false,
            updateSlotInfo: {},

            movementStep: 5,
            replaceableSlot: null,
        }
    },
    methods:{

        tableSelectedItem(){
            return this.tableHeader.selectedItem()
        },
        setupTrainings({startDate, endDate}){

            let calendarUid = this.$route.params.calendarUid
            // let columnCount = this.tableHeader.columnCount
            let firstDate = startDate || this.tableHeader.firstDate()
            let lastDate = endDate || this.tableHeader.lastDate()
            
            if (this.tableMode.isCompare) {
                let compareCalendarUid = this.$store.getters.compareCalendarUid
                this.$store.dispatch('update-compare-slots', {
                    uid: compareCalendarUid,
                    from: firstDate,
                    to: lastDate,
                }).then(() => {
                    this.$store.dispatch('update-slots', {
                        uid: calendarUid,
                        from: firstDate,
                        to: lastDate,
                    }).then(() => {
                        if (this.tableMode.isCompare){
                            this.setupCompareMode()
                        }
                    }).catch((err) => {console.error(err)})
                })
                return
            }

            return this.$store.dispatch('update-slots', {
                uid: calendarUid,
                from: firstDate,
                to: lastDate,
            }).catch((err) => {console.error(err)})
        },

        isSpecialDay(date){
            return this.$store.getters.isSpecialDay(date)
        },
        isSelected(date){
           return date.getDate() === this.currentDay.getDate() && date.getMonth() === this.currentDay.getMonth()
        },
        slotMoveToLastPlace(index){
            bus.$emit(`new-card-${index}`, 'move-to-last-place')
            this.$store.dispatch('update-slot-styles', {
                index, 
                isChangeColumn: false,
            })
            this.slotIsChangePlace = false
        },
        moveToNearestPlanningDate(){
            bus.$emit('calendar', 'select-date', {date: this.nearestPlanningDate, isSetupToday: true})
        },

        updateSlotTime({date, index, isAfterPut=false}){
            this.$store.dispatch('update-slot-time', {
                slotIndex:index,
                isAfterPut,
                date,
            })
            if (this.slotIsChangePlace){
                this.$store.dispatch('update-slot-styles', {
                    index, 
                    isChangeColumn: false,
                })
                this.slotIsChangePlace = false
            }
        },    
        updateSlotDate({date, index, futureColumnNumber, isAfterPut}){

            let slotIndex = index
            // let oldColumnIndex = newDate.getDate()
            // bus.$emit('column-' + oldColumnIndex, 'update-new-slot-count')
            return this.$store.dispatch('update-slot-date',{
                futureColumnNumber,
                slotIndex,
                date,
                isAfterPut,
            }).then(({newDate}) => {
                // Select new date
                bus.$emit('calendar', 'select-date', {date: newDate})
                // Disable planning mode
                this.$nextTick().then(() => {
                    this.$store.dispatch('update-table-mode', {isQuestion: false})
                    
                    if (this.tableMode.isCompare){
                        bus.$emit('column-' + date.getDate(), 'check-compare-slots', {
                            index: index,
                            uid: -1,
                            isCompare: false,
                        })
                    }
                })
                let newColumnIndex = newDate.getDate()
                // bus.$emit('column-' + newColumnIndex, 'update-new-slot-count')

                if (this.slotIsChangePlace){
                    this.$store.dispatch('update-slot-styles', {
                        index, 
                        isChangeColumn: false,
                    })
                    this.slotIsChangePlace = false
                }
            }) 
        },
        createSlotCopy(){
            let calendarUid = this.$route.params.calendarUid

            let copiedSlot = this.$store.getters.copiedSlot
            if (!copiedSlot)
                return
            let slotData = copiedSlot.settings
            // let slot = this.$store.getters.getSlotByIndex(slotInfo.index)
            // bus.$emit(`new-card-${slot.index}`, 'move-to-last-place')
            // let slotData = {
                //     ...slot.settings,
            // }
            
            let date = copiedSlot.period.start.date
            let calendarTimezone = this.$store.getters.getTimezone
            let browserTimezone = this.$store.getters.browserTimezone
            date = convertTZ(date, calendarTimezone.name, browserTimezone.name)
            
            slotData["startAt"] = UTCString(date)
            slotData["title"] = copiedSlot.title
            slotData["dur"] = copiedSlot.period.duration
            
            delete slotData.id

            copiedSlot.index = -1
            copiedSlot.settings.id = -1
            copiedSlot.period.index = -1
            this.$store.dispatch('add-slot', {slots: [copiedSlot]})
            this.$store.dispatch('update-copied-slot', null)
            CMS.slots.upload(calendarUid, slotData).then((slots) => {

                if (slots.err){
                    throw new Error(slots.err)
                }

                let newSlotData = slots[0]
                this.$store.dispatch('delete-slot', {slot:copiedSlot})
                copiedSlot.index = newSlotData.id
                copiedSlot.settings.id = newSlotData.id
                copiedSlot.period.index = newSlotData.id
                copiedSlot.cardPeriod.index = newSlotData.id

                this.$store.dispatch('add-slot', {slots: [copiedSlot]})
                // this.$store.dispatch('update-slot-styles', {
                //     index: slot.index,
                //     isChangeColumn: false,
                // })
                this.slotIsChangePlace = false
            }).catch((err) => console.error(err))
        },
        setupCompareMode(){
            this.tableHeader.week.forEach(headerItem => {
                let columnIndex = headerItem.date.getDate()
                bus.$emit('column-' + columnIndex, 'setup-compare-slots')
            })
        },
        disableCompareMode(){

            this.$store.dispatch('clear-compare-slots')
            setTimeout(() => {
                this.tableHeader.week.forEach(headerItem => {
                    let columnIndex = headerItem.date.getDate()
                    bus.$emit('column-' + columnIndex, 'clear-compare-slots')
                })
            }, 400)
            setTimeout(() => this.$store.dispatch('update-table-mode', {isCompare:false}), 800)
        },
        slotMovementHandler(payload){
            let isChangeDate = payload.isChangeDate
            if (isChangeDate){
                this.updateSlotDate(payload)
            } else {
                this.updateSlotTime(payload)
            }
            return
        }
    },
}
</script>

<style scoped>
.calendar__table{
    position: relative;
}

.column__cont{
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 300px;
    border: 1px solid grey;
    border-top: none;
}

.column__cont > * + * {
    border-left: 1px solid grey;
}



/* Create button */

.add-training{
    position: fixed;
    bottom: 55px;
    right: 15px;

    height: 60px;
    width: 60px;

    background: white;
    border: 3px solid royalblue;
    border-radius: 100px;

    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.add-training svg{
    color: #4169e1;
    height: 40px;
    width: 40px;
}

.nearest_planning_date{
    position: fixed;
    z-index: 4;
    bottom: 130px;
    right: 5px;

    height: 76px;
    width: 140px;

    font-size: 14px;
    text-align: end;

    display: flex;
    flex-direction: column;
    gap: 10px;

    align-items: flex-end;

    padding: 5px;

    background: white;
    border: #6b6b6b solid 1px;
    border-radius: 5px;
}

.move__button{

    height: 30px;
    width: 70px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 12px;
    color: white;

    border: #6b6b6b solid 1px;
    background: #4169e1;
    border-radius: 5px;
}

#table{
    position: relative;
}

/* Space with working time */

.working-time-borders{
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.hour-border{
    background: lightgrey;
}

.working-time{
    display: grid;
    height: inherit;
}

/* Slot trash bucket */

.trash_slot_bucket_cont{
    color: #f28383;
    height: 50px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    margin-top: 10px;
    bottom: 0;
    z-index: 3;
    transition: width 2s, height 2s;
}

.bucket__border{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 50px;
    width: 50px;

    border-radius: 50px;
}

.preparation{
    color: red;
    border: 1px solid red;
    -webkit-box-shadow: 0px 0px 32px 3px rgba(255, 11, 0, 0.5);
-moz-box-shadow: 0px 0px 32px 3px rgba(255, 11, 0, 0.5);
box-shadow: 0px 0px 32px 3px rgba(255, 11, 0, 0.5);
}

.garbage-enter-active {
	animation: fade .3s;
}

.garbage-leave-active {
	animation: fade .3s reverse;
}
</style>