<script>
export default {
    name: 'TimeMixin',
    props: {
        date: {
            type: Date,
            default: function () { return new Date() }
        },
        holidays: {
            type: Array,
            default: function () { return [] }
        },
        daysHeader: {
            type: Array,
            default: function () { return [] }
        }
    },
    data(){
        return{
        }
    },
    methods: {
        /*
            Сonditional date check
        */
        isFinished(time){
            // Training is finished? 
            let currentDate = new Date()
            let trainingDate = this.date
            trainingDate.setHours(time.hour, time.minute)
            return (currentDate.getTime() / 1000) - (trainingDate.getTime() / 1000) > 0      
        },
        isEqualsDate(first, second){
            return first.getDate() === second.getDate() && first.getMonth() === second.getMonth()
        },
        isEqualsTime(first, second){
            return first.getMinutes() === second.getMinutes() && first.getHours() === second.getHours()
        },
        isValid(date, next){

            if (next !== undefined) {
                date = new Date(date.getTime() + 86400 * next * 1000)
            }
            let now = new Date()
            if(date.getTime() - now.getTime() < 0){
                return false
            }
                
            let d = date.getDate()
            let result = false
            this.daysHeader.forEach((day) => {
                result = result || d === day.getDate()
            })

            return result
        },
        /*
            
        */
        getNextDay(date, next){
            // Seconds in one day
            const inDay = 86400

            if (date === undefined){
                date = new Date()
                // Set zero time
                date.setHours(0,0,0)
            }
            
            // Date to seconds
            let seconds = Math.round(date.getTime() / 1000)

            // Return Monday + next day with time 00:00:00 of this week
            return new Date((seconds + next * inDay ) * 1000)
        },
        minuteRound(date){
            let minute = date.getMinutes()
            let minuteUnit = minute % 10;
            if (minuteUnit < 5 && minuteUnit != 0){
                date.setMinutes(5 - minuteUnit + minute)
            }
            if (minuteUnit >= 5) {
                date.setMinutes(10 - minuteUnit + minute)
            }

            if (minute > 49 && minuteUnit > 5) {
                date.setHours(date.getHours(), 0)
            }
        },
        
    },
}
</script>