<template>
    <div class='change-mode' 
        v-on:click="setPlanningMode"
        v-bind:class="{
            selected: isPlanningMode
        }"
    >
        {{isPlanningMode?'Complete':'Plans'}}
    </div>
</template>

<script>
export default {
    name: 'ChangeMode',
    props:{
        isPlanningMode : Boolean,
    },
    methods: {
        setPlanningMode(){
            this.$store.dispatch('update-table-mode', {
                isPlanning: !this.isPlanningMode,
            })
        }
    },

}
</script>

<style scoped>
.change-mode{
    font-size: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 30px;
    width: 70px;

    background: white;
    color: #0075ff;

    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    outline: #6b6b6b solid 1px;
    border-radius: 8px;
}
.change-mode:active{
    background: #0060D3;
    color: white;
}
.selected{
    background: #0075ff;
    color: white;
}

</style>