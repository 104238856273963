<template>
    <div class="filter-item">
        {{title}}
    </div>
</template>

<script>
export default {
    name: 'Item',
    props:{
        title: String,
    }
}
</script>

<style scoped>

.filter-item{
    border: black solid 1.5px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
</style>