<template>
    <div id="studio"
    v-if="calendarIsLoaded"
    v-bind:style="{
        height : calendarRestricted || calendarIsNotFound ? 'inherit' : 'auto',
    }">
        <span v-if="calendarIsNotFound" class="page">
            <NotFoundMessage/>
        </span>
        <span v-else
        v-bind:class="{
            page: calendarRestricted,
        }">
            <About :isSwiped="isSwiped" :changeMode="setSwiped" :imageUrl="imageUrl"/>
            <Stories 
            v-if="!isDefaultCalendar && !calendarRestricted"
            :isSwiped="isSwiped" :imagePaths="storiesImagePaths" />
            <RestrictedMessage v-if="calendarRestricted"/>
            <Calendar
                v-if="!calendarRestricted && calendarUid"
                :isSwiped="isSwiped"
                :holidays="holidays"
                :specialDays="specialDays"
                :hasFilter="true"
            />
        </span>
        <SlotToolBar/>
    </div>
</template>

<script>
import About from "../components/about/About.vue";
import Calendar from "../components/calendar/Calendar.vue";
import Stories from "../components/stories/Stories.vue";
import Header from "../components/header/Header.vue";
import CalendarError from "./CalendarError.vue";
import StaticMenu from "../components/static-menu/StaticMenu.vue";
import RestrictedMessage from '../components/calendar/messages/RestrictedMessage.vue'
import NotFoundMessage from '../components/calendar/messages/NotFoundMessage.vue'

import { globalBus } from "../GlobalEventBus.js";

import smoothScroll from "../scripts/promiseScroll.js";

const SWIPE_COMPLITED = 48

export default {
    name: "Studio",
    components: {
        Calendar,
        Header,
        About,
        Stories,
        CalendarError,
        StaticMenu,
        NotFoundMessage,
        RestrictedMessage,
        SlotToolBar: () => 
            import("../components/slot-toolbar/SlotToolBar.vue"),
    },
    computed: {
        calendarUid() {
            return this.$store.getters.calendarUid;
        },
        isDefaultCalendar() {
            let defaultCalendar = this.$store.getters.getDefaultCalendar;
            let currentCalendar = this.$store.getters.calendarUid;
            return defaultCalendar == currentCalendar;
        },
        calendarRestricted() {
            return this.$store.getters.calendarRestricted
        },
        calendarIsNotFound() {
            return this.$store.getters.calendarIsNotFound
        },
        calendarIsLoaded(){
            return this.$store.getters.calendarIsLoaded
        }
    },
    beforeDestroy() {
        globalBus.$off("calendar-page");
    },
    mounted() {
        this.scrollComponent = document.getElementById("layout__content")
        this.scrollProgress = this.scrollComponent.scrollTop
        this.scrollComponent.removeEventListener("scroll", this.scrollHandler)
        this.scrollComponent.addEventListener("scroll", this.scrollHandler)
        globalBus.$on("calendar-page", "set-swipped", (info) => {
            this.setSwiped(info.isSwipeeScrolld);
        });

        // Setup the old scroll position
        let oldScroll = this.$store.getters.currentScroll
        if (oldScroll != 0) {
            this.scrollComponent.scrollTo(0, oldScroll + 120)
        }
    },
    data() {
        return {
            isSwiped: false,
            scrollProgress: 0,
            scrollComponent: null,       

            // About component props
            imageUrl: "",

            // Calendar component props
            holidays: [],
            specialDays: [],

            storiesImagePaths: ["", "", "", "", "", ""],
        };
    },
    methods: {
        setSwiped(setValue) {
            let isSwipeComplited = this.scrollComponent.scrollTop >= SWIPE_COMPLITED
            if (isSwipeComplited && setValue == false) {
                smoothScroll(0, this.scrollComponent)
                    .then(() => {
                        this.isSwiped = false
                    })
            } else {
                this.isSwiped = setValue
            }
        },
        scrollHandler() {
            let currentScroll = this.scrollComponent.scrollTop
            let isScrollDown = currentScroll > this.scrollProgress
            let isSwipeComplited = currentScroll >= SWIPE_COMPLITED
            
            if (isScrollDown && isSwipeComplited && !this.isSwiped)
                this.setSwiped(true)
            // else if (currentScroll < 0)
            //     this.setSwiped(false)
            // else if (!isScrollDown && !isScrollDown && this.isSwiped)
            //     this.setSwiped(false)

            this.scrollProgress = currentScroll;
            this.$store.dispatch("update-scroll", { currentScroll })
        },
    },
};
</script>

<style scoped>
#studio{
    width:100%;
}

.page{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.settings__wrapper {
    background: white;
    position: relative;
    height: 100vh;
    z-index: 10;
}
</style>