<template>
    <div class="calendar_hours_ruler">
        <div class="hour__cont"
            v-for="(n, index) in totalHours"
            :key="'hour__' + index">
            <div class="line big" v-if="index != 0"></div>
            <div class="hour">{{n}}:00</div>
            <div class="line small"></div>
        </div>
        <div class="red__line"
        ref="red-line"
        v-if="redLineIsVisible"
            v-bind:style="{
                top:  redLinePositionY + 'px',
            }">
            <hr color="red" size="2px" width="20px">
        </div>
    </div>
</template>

<script>
import { cssVh } from '../../../scripts/cssUnits.js';

export default {
    name: "Hours",
    data() {
        return {
            position: 0,
            isScrolled: false,
        }
    },

    computed: {
        redLinePositionY: {
            get(){
                this.position = this.getPosition(new Date())
                let ceilSize = this.$store.getters.tableCeilSize
                return ceilSize.height * this.position
            },
            set(newPosition){
                this.$nextTick().then(this.updateScrollPosition)
                this.position = newPosition
            }
        },
        
        workHours(){
            return this.$store.getters.calendarWorkHours
        },
        calendarSupportedHours(){
            return this.$store.getters.calendarSupportedHours
        },
        totalHours(){
            this.$nextTick().then(this.updateScrollPosition)
            return this.workHours.concat(this.calendarSupportedHours).sort(function(a, b){return a-b})
        },
        
        redLineIsVisible(){
            let date = new Date()
            let dateIsOverStart = date.getHours() > this.workHours[this.workHours.length-1]
            let dateIsOverEnd = date.getHours() < this.workHours[0]

            return !dateIsOverStart && !dateIsOverEnd
        }
    },

    created() {
        setInterval(() => {this.redLinePositionY = this.getPosition(new Date())}, 60000)
    },
    
    methods:{
        getPosition(date){
            let position = date.getHours() + ( date.getMinutes() / 60 ) - this.workHours[0]
            return position 
        },
        updateScrollPosition(){
            if (!this.redLineIsVisible || !this.redLinePositionY || this.isScrolled)
                return
            let redLine = this.$refs['red-line']
        
            let redlineTablePosition = this.redLinePositionY
            let redLineWindowPosition = redLine.getBoundingClientRect().top 

            let tableVisibleHeight = 100 * cssVh - 32 - 72 - 51 - 48 - 50

            let screenStart = window.scrollY
            let screenEnd = screenStart + cssVh * 100
            let calendarPage = document.getElementById('layout__content')

            // if the red line is below the screen
            if (redLineWindowPosition > screenEnd ){
                let futurePosition = calendarPage.scrollTop + tableVisibleHeight / 2
                     + (redlineTablePosition - tableVisibleHeight)
                this.scrollContent(futurePosition)          
            }
            
            // if the red line is above the screen
            if (redLineWindowPosition < screenStart){
                let futurePosition = calendarPage.scrollTop - tableVisibleHeight / 2 
                    + (redlineTablePosition - tableVisibleHeight)
                this.scrollContent(futurePosition)
            }
        },
        scrollContent(futurePosition){
            let calendarPage = document.getElementById('layout__content')

            calendarPage.scrollTo({
                top: futurePosition,
                behavior: 'smooth'
            })
            this.isScrolled = true   
        }
    }
}
</script>

<style scoped>

.calendar_hours_ruler{
    position: relative;
}
hr{
    margin: 0;
}
.hour__cont{
    height: 54px;
    position: relative;
}
.hour{
    position: relative;
    font-size:10px;
    margin-top: 4px;
    margin-bottom: 4px;
    float: right;
    margin-right: 2px;
    color: rgb(143, 143, 143);
    z-index: 2;
}

.big{
    width: 15px;
    float: right;
}
.small{
    width: 8px;
    float: right;
}
.line{
    border-bottom: 1px solid skyblue;
    margin-right: 4px;
}
.small{
    position:absolute;
    top : 50%;
    right: 0;
}
.red__line{
    position:absolute;
    float:right;
    color: red;
}

.red__line hr {
    background: red;
}
.hour__cont:first-child{
    height: 54px;
}
</style>