var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.headerItem.scale > 0.2)?_c('div',{staticClass:"header_item_cont",class:{
        holiday: _vm.isHoliday || _vm.isSpecialDay,
        selected: _vm.isSelected,
    },style:({ 
        width: ((_vm.getColumnWidth(_vm.isSelected) * _vm.headerItem.scale) + "px"),
        opacity: _vm.headerItem.scale,
    })},[_c('transition',{attrs:{"name":"slot__toolbar"}},[(_vm.slotToolBarIsOpen && _vm.slotToolBarDateSlots.length)?_c('div',{staticClass:"item-selected-slots"},[_vm._v(" "+_vm._s(_vm.slotToolBarDateSlots.length)+" ")]):_vm._e()]),_c('transition',{attrs:{"name":"item__toolbar"}},[(_vm.toolBarIsOpen)?_c('div',{staticClass:"item-toolbar"},[_c('div',{staticClass:"item-toolbar__option",on:{"click":_vm.attachHandler}},[_c('div',[_c('img',{attrs:{"src":require("@/assets/push-pin-outline.svg")}})]),(!_vm.isAttached)?_c('div',[_vm._v("Pin")]):_c('div',[_vm._v("Unpin")])]),_c('div',{staticClass:"item-toolbar__option",on:{"click":_vm.copyHandler}},[_c('div',[_c('img',{attrs:{"src":require("@/assets/file-copy-outline-rounded.svg")}})]),_c('div',[_vm._v("Copy")])]),_c('div',{staticClass:"item-toolbar__option",on:{"click":_vm.pasteHandler}},[(!_vm.copySlotsBuffer())?_c('div',{staticClass:"disable"}):_vm._e(),_c('div',[_c('img',{attrs:{"src":require("@/assets/content-paste-go-rounded.svg")}})]),_c('div',[_vm._v("Paste")])])]):_vm._e()]),_c('div',{staticClass:"item",on:{"touchstart":_vm.clickHandler,"touchend":_vm.pointerUpHandler}},[(_vm.isAttached)?_c('div',{staticClass:"attached__selector"},[_c('img',{attrs:{"src":require("@/assets/push-pin-outline.svg"),"height":"20"}})]):_vm._e(),(_vm.isToday(_vm.headerItem.date))?_c('div',{staticClass:"today__selector"}):_vm._e(),(_vm.isSelected)?_c('div',{staticClass:"content big"},[_vm._v(" "+_vm._s(((_vm.headerItem.date.getDate()) + " " + (_vm.fullMonths[_vm.headerItem.date.getMonth()])))+" ")]):_c('div',{staticClass:"content small",on:{"click":function($event){return _vm.selectDay()}}},[(_vm.getColumnWidth(_vm.isSelected) * _vm.headerItem.scale > 21)?_c('div',{staticClass:"item__field",style:({
                opacity: _vm.headerItem.scale,
            })},[_vm._v(" "+_vm._s(_vm.headerItem.date.getDate())+" ")]):_vm._e(),(_vm.getColumnWidth(_vm.isSelected) * _vm.headerItem.scale > 21)?_c('div',{staticClass:"item__field month",style:({
                opacity: _vm.headerItem.scale,
            })},[_vm._v(" "+_vm._s(_vm.months[_vm.headerItem.date.getMonth()])+" ")]):_vm._e()])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }