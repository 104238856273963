<template>
    <div class="calendar_header_cont">
        <div class="calendar__header" v-bind:style="{
            height : `${tableCeilSize.height}px`
        }">
            <Item v-for="headerItem, index in tableHeader.leftSide()"
                :key="`calendar_header_item_left-${index}`"
                :headerItem="headerItem"
                :isHoliday="isHoliday(headerItem)"
                :isSelected="isSelected(headerItem)"
                :isSpecialDay="isSpecialDay(headerItem)"
            />
            <Item :headerItem="tableSelectedItem"
                :isHoliday="isHoliday(tableSelectedItem)"
                :isSelected="isSelected(tableSelectedItem)"
                :isSpecialDay="isSpecialDay(tableSelectedItem)"
            />
            <Item v-for="headerItem, index in tableHeader.rightSide()"
                :key="`calendar_header_item_right-${index}`"
                :headerItem="headerItem"
                :isHoliday="isHoliday(headerItem)"
                :isSelected="isSelected(headerItem)"
                :isSpecialDay="isSpecialDay(headerItem)"
            />
        </div>
    </div>
</template>

<script>

import Item from './Item.vue'
import { isEqualDate } from '../../../scripts/date'

export default {
    name: "Header",
    components:{
        Item,
    },
    props:{
        currentDay: Date,
    },
    computed: {
        tableCeilSize(){
            return this.$store.getters.tableCeilSize
        },
        tableHeader(){
            return this.$store.getters.tableHeader
        },
        tableSelectedItem(){
            return this.tableHeader.selectedItem()
        }
    },
    methods:{
        isHoliday(headerItem){
            return this.$store.getters.isHoliday(headerItem.date)
        },
        isSpecialDay(headerItem){
            return this.$store.getters.isSpecialDay(headerItem.date)
        },
        isSelected(headerItem){
           return isEqualDate(headerItem.date, this.currentDay)
        },
    }
}
</script>

<style scoped>
.calendar_header_cont{
    padding-left: 30px;
    min-width: 300px;
    background: white;
    position: sticky;
    top: calc(70px + 50px); /* header + small-about + calendar-filter  */
    z-index: 4;
}
.calendar__header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 30px;
    border: 1px solid grey;
}

.calendar__header > * + * {
    border-left: 1px solid grey;
}

</style>