var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-slot",class:{
		draggable: _vm.dragging,
	},style:({
		top			: (_vm.posY + "px"),
		left 		: (_vm.posX + "px"),
		width		: ((_vm.getColumnWidth(true)) + "px"),
		display		: 'none',
		color		: _vm.slotToCopy ? _vm.slotToCopy.settings.cellTextColor : null,
		background	: _vm.slotToCopy ? _vm.slotToCopy.settings.cellBackgroundColor : null,
	}),attrs:{"id":"create-slot"},on:{"dragstart":function () { return false; }}},[_c('div',{staticClass:"create-slot__header"},[_c('div',[_vm._v(_vm._s(_vm.slotToCopy && _vm.slotToCopy.title ? _vm.slotToCopy.title : "New slot"))]),_c('div',[_vm._v(_vm._s(_vm.getTimeString()))])])])}
var staticRenderFns = []

export { render, staticRenderFns }