<template>
    <div id='stories' :key="currentPage" v-if="!isSwiped">
        <span
            v-touch:swipe.right="earlyPage"
            v-touch:swipe.left="nextPage"
        >
            <Modal 
                :showModal='showModal'
                :close='close'
            />
            <Item 
                v-for="(item, index) in itemArray"
                :key="index"
                :imagePath="item"
                :show="show"
            />
        </span>
    </div>
</template>

<script>
import Item from './Item.vue'
import Modal from './Modal'

export default {
    name: 'Stories',
    props: {
        imagePaths: Array,
        isSwiped: Boolean
    },
    data(){
        return{
            currentPage: 0,
            itemArray: [],
            showModal: false,
        }
    },
    watch:{
        currentPage: function(){
            this.itemArray = this.imagePaths.slice(this.currentPage*5, (this.currentPage+1) * 5)
        }
    },
    beforeMount(){
        this.itemArray = this.imagePaths.slice(this.currentPage*5, (this.currentPage+1) * 5)
    },
    components:{
        Item,
        Modal,
    },
    methods:{
        nextPage(){

            this.currentPage += 1
        },
        earlyPage(){

            if (this.currentPage !== 0){
                this.currentPage -= 1
            }
        },
        show () {
            this.showModal = true
        },
        close () {
            this.showModal = false
        }
    },

}
</script>

<style scoped>
#stories{
    margin-bottom: 15px;
    margin-left: 30px;
    max-height: 60px;
    height: 7vh;
}
</style>