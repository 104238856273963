<template>
    <div class="menu__item" @click="usage(title)">
        <div class="title">{{title}}</div>
    </div>
</template>

<script>
export default {
    name:'Item',
    props:{
        title: String,
        url: String,
        usage: {
            type: Function,
            default: () => {console.log('item')}
        },
    }
}
</script>

<style scoped>
.menu__item{
    text-align: center;
    padding: 9px 17px;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
</style>