<template >
<div class="header_item_cont"
    v-if="headerItem.scale > 0.2"
    v-bind:style="{ 
        width: `${getColumnWidth(isSelected) * headerItem.scale}px`,
        opacity: headerItem.scale,
    }"
    v-bind:class="{
        holiday: isHoliday || isSpecialDay,
        selected: isSelected,
    }">
    <!-- Count of selected slots in this column -->

    <transition name="slot__toolbar">
        <div class="item-selected-slots" v-if="slotToolBarIsOpen && slotToolBarDateSlots.length">
            {{slotToolBarDateSlots.length}}
        </div>
    </transition>

    <!-- Support toolbar for activities -->

    <transition name="item__toolbar">
        <div class="item-toolbar" v-if="toolBarIsOpen">
            <div class="item-toolbar__option" @click="attachHandler">
                <div><img src="@/assets/push-pin-outline.svg"></div>
                <div v-if="!isAttached">Pin</div>
                <div v-else>Unpin</div>
            </div>
            
            <div class="item-toolbar__option" @click="copyHandler">
                <div><img src="@/assets/file-copy-outline-rounded.svg"></div>
                <div>Copy</div>
            </div>
            
            <div class="item-toolbar__option" @click="pasteHandler">
                <div class="disable" v-if="!copySlotsBuffer()"></div>
                <div><img src="@/assets/content-paste-go-rounded.svg"></div>
                <div>Paste</div>
            </div>
        </div>
    </transition>

    <div class="item"
    @touchstart="clickHandler"
    @touchend="pointerUpHandler">
        <!-- <div class="" style="font-size:10px">
            {{(headerItem.scale).toFixed(1)}}
        </div> -->

        <div class="attached__selector" v-if="isAttached">
            <img src="@/assets/push-pin-outline.svg" height="20">
        </div>

        <div class="today__selector" v-if="isToday(headerItem.date)"></div> 

        <div class="content big" v-if="isSelected">
            {{`${headerItem.date.getDate()} ${fullMonths[headerItem.date.getMonth()]}`}}
        </div>
        
        <div class="content small" v-else v-on:click="selectDay()">
            <!--  -->

            <div class="item__field" 
            v-if="getColumnWidth(isSelected) * headerItem.scale > 21"
            v-bind:style="{
                opacity: headerItem.scale,
            }">
                {{headerItem.date.getDate()}}
            </div>
            <div class="item__field month"
            v-if="getColumnWidth(isSelected) * headerItem.scale > 21"
            v-bind:style="{
                opacity: headerItem.scale,
            }">
                {{months[headerItem.date.getMonth()]}}
            </div>
        </div>

        <!-- <div v-if="headerItem.scale < 1">
            {{ (itemWidth() * headerItem.scale).toFixed(1) }}
        </div> -->
    </div>
</div>
</template>

<script>
import { convertTZ, isEqualDate, isToday, UTCString } from '../../../scripts/date.js'
import {bus} from '../EventBus.js'

import { enLongMonths, enShortMonths} from './months'
import CalendarHeaderItem from '../../../scripts/calendarHeaderItem.js'

export default {
    name: "Item",
    props:{
        headerItem: CalendarHeaderItem,
        isSelected: {
            type: Boolean,
            default: false,
        },
        isHoliday: Boolean,
        isSpecialDay: Boolean
    },
    computed: {
        slotToolBarSlots(){
            return this.$store.getters.tableSlotsToolBar
        },
        slotToolBarIsOpen(){
            return this.slotToolBarSlots != undefined
        },
        slotToolBarDateSlots(){
            let slots = this.slotToolBarSlots
            if (!slots) return []
            return slots
                .filter((slot) => isEqualDate(slot.period.start.date, this.headerItem.date))
        },
        slotToolBarDateSelected(){
            let allSlots = this.$store.getters.calendarSlotsByDate(this.headerItem.date)
            let allSlotsDates = Object.values(allSlots)
                .map((slot) => slot.period.start.date)
            if (allSlotsDates.length == 0) return

            let slotToolbarSlots = this.slotToolBarSlots
            if (!slotToolbarSlots) return

            let slotToolbarSlotsDates = this.slotToolBarSlots
                .map(slot => slot.period.start.date)

            return this.isEqual(allSlotsDates, slotToolbarSlotsDates)
        },
        tableIsPlanning(){
            return this.$store.getters.tableMode.isPlanning
        },
        isAttached(){
            let pinnedItem = this.$store.getters.tableHeader.pinnedItem
            if (!pinnedItem)
                return false
            return isEqualDate(pinnedItem.date, this.headerItem.date)
        },
        columnCount(){
            return this.$store.getters.tableHeader.columnCount
        }
    },
    data(){
        return{
            months: enShortMonths,
            fullMonths: enLongMonths,
            toolBarIsOpen: false,
            isTouched: false,
        }
    },
    methods:{
        isToday: isToday,
        selectDay(){
            bus.$emit('calendar', 'select-date', {
                date: this.headerItem.date
            })
        },
        isEqual(dateArr1, dateArr2){
            
            /*
                Find the date from dateArr1 which not exist in the dateArr2
            */
            if (dateArr1.length == 0 || dateArr2.length == 0) return

            let difference = dateArr1.find((date) => {
                let foundDate = dateArr2.find((date2) => {
                    return date.getTime() - date2.getTime() == 0
                })
                return foundDate == undefined
            })
            return difference == undefined
        },
        clickHandler(){
            this.isTouched = true
            setTimeout(this.longTabHandler, 450)
        },
        longTabHandler(){
            if (!this.isTouched) return

            if (this.tableIsPlanning && this.slotToolBarIsOpen){
                this.selectAllColumn()
                return
            }
            if (this.isSelected){
                if (!this.toolBarIsOpen){
                    this.openToolBar()
                } else {
                    this.closeToolBar()
                }
                return
            }
        },
        openToolBar(){
            if (this.toolBarIsOpen) return
            window.navigator.vibrate(65)
            this.toolBarIsOpen = true
        },
        closeToolBar(){
            if (!this.toolBarIsOpen) return
            this.toolBarIsOpen = false
        },
        selectAllColumn(){
            window.navigator.vibrate(65)
            this.$store.dispatch('update-slot-toolbar-date', {
                date : this.headerItem.date
            })
        },
        pointerUpHandler(){
            this.isTouched = false
        },

        attachHandler(){
            this.$store.getters.tableHeader.updatePinnedItem(
                this.isAttached ? 
                    null : 
                    this.headerItem.date
            ) 
            this.closeToolBar()
        },
        
        copyHandler(){
            let slots = Object.values(this.$store.getters.calendarSlotsByDate(this.headerItem.date))
            if (slots.length != 0){
                this.$store.dispatch("update-slot-copy-buffer", slots)
            }
            this.closeToolBar()
        },

        copySlotsBuffer(){
            return this.$store.getters.tableSlotsCopyBuffer
        },
        getColumnWidth(isSelected) {
            let ceilSize = this.$store.getters.tableCeilSize
            if (isSelected) {
                return ceilSize.selectedWidth
            } else {
                return ceilSize.defaultWidth
            }
        },
        pasteHandler(){
            let pasteSlots = this.copySlotsBuffer()
            if (!pasteSlots) return 

            pasteSlots = pasteSlots.map(slot=> {
                let newSlot = slot.makeCopy()
                newSlot.period = newSlot.period.projection(this.headerItem.date)
                return newSlot
            })

            let currentColumnSlots = this.$store.getters.calendarSlotsByDate(this.headerItem.date)
            let isIntersection = false
            Object.values(currentColumnSlots).forEach(slot => {
                pasteSlots.forEach((pastingSlot) => {
                    let checkResult = slot.period.intersection(pastingSlot.period)
                    if (checkResult == true){
                        isIntersection = true
                    }
                })
            })

            if (isIntersection){
                // TODO : make alert to view collision 
                return
            }

            let calendarUid = this.$route.params.calendarUid
            let browserTimezone = this.$store.getters.browserTimezone
            let calendarTimezone = this.$store.getters.getTimezone
            
            let submitData = pasteSlots.map((slot) => {
                let slotData = slot.toSubmitData()
                slotData["startAt"] = convertTZ(slotData["startAt"], calendarTimezone.name, browserTimezone.name)
                slotData["startAt"] = UTCString(slotData["startAt"])
                return slotData
            })
            
            this.$store.dispatch('create-slots', {calendarUid, slotsData: submitData})

            this.closeToolBar()
        }
    }
}
</script>

<style scoped>
    .header_item_cont{
        background: rgb(233, 233, 233);
        min-height: 30px;        
        position: relative;
        transition: width .2s;
        /* transition: width .2s; */
        /* box-sizing: border-box; */
    }

    .item{
        height: 100%;
        width: 100%;
        position: relative;
    }
        
    .header_item_cont:last-child {
        border-right: 0;
    }

    .item > *{
        user-select: none;
    }

    .content{
        cursor: pointer;
        width: 100%;
        font-size: 14px;
        text-align: center;
        height: 100%;
        overflow: hidden;
    }

    .content > * {
        text-overflow: hidden;
        overflow: hidden;
        width:100%;
        transition: opacity .2s;
        transition: width .2s;
    }
    
    .content.small{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .content.big{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .selected{
        min-width: 50px;
    }
    .today__selector{
        display: block;
        height: 3px;
        width: 100%;
        background: royalblue;
        position: absolute;
        top: 0;
    }
    .holiday{
        background: #F0D2D0;
    }
    .month{
        color: grey;
        font-size: 11px;
    }
    .selector{
        height: 12px;
        width: 12px;
        border: 1px solid black;
        border-radius: 10px;
    }

    .item-selected-slots{
        position: absolute;
        top: -8px;
        left:-1px;
        z-index: 6;

        height: 14px;
        width: inherit;
        border: 1px solid #4098FF;
        background: white;
        color: #4098FF;
        border-radius: 8px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        user-select: none;
    }

    .slot__toolbar-enter-active{
        animation: .25s slot-toolbar ease-in;
    }
    .slot__toolbar-leave-active{
        animation: .25s slot-toolbar reverse ease-out;
    }

    @keyframes slot-toolbar {
        from {
            top: -2px;
            opacity: 0;
        }
        to {
            opacity: 1;
            top: -8px;
        }
    }

    .item-toolbar{
        width: 100%;
        border: 1px solid grey;
        border-radius: 8px;
        background: white;

        overflow: hidden;
        position: absolute;
        top: -100px;
        left: -1px;
    }

    .item-toolbar__option {
        display: flex;
        flex-direction: row;
        gap: 5px;
        font-size: 14px;
        user-select: none;
        position: relative;
        padding: 4px;
    }

    .item-toolbar__option > .disable{
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba(182, 182, 182, 0.5);
        top: 0;
        left: 0;
    }

    .item-toolbar__option + .item-toolbar__option {
        border-top: 1px solid lightgray;
    }

    .item__toolbar-enter-active{
        animation: .27s item-toolbar ease-in;
    }

    .item__toolbar-leave-active{
        animation: .27s item-toolbar ease-out reverse;
    }

    @keyframes item-toolbar {
        from {
            top: -58px;
            opacity: 0;
        }
        to {
            opacity: 1;
            top: -100px;
        }
    }

    .attached__selector{
        position: absolute;
        top: 0;
        left: 0;
    }
</style>
