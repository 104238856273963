var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.columnItem.scale > 0.2)?_c('div',{ref:'calendar-column-' + _vm.columnItem.date.getDate(),staticClass:"calendar-column",class:{
    selected: _vm.isSelected,
},style:({ 
    width: _vm.getColumnWidth(_vm.isSelected) * _vm.columnItem.scale + 'px',
    opacity: _vm.columnItem.scale,
    background: _vm.columnColor(),
}),attrs:{"id":'calendar-column-' + _vm.columnItem.date.getDate()}},[_c('div',{staticClass:"column_cell_cont",style:({
            background: _vm.columnIsInQuestion && !_vm.questionDetails.isNearestPlace ? _vm.questionDetails.color : '',
            opacity: _vm.columnIsInQuestion ? '0.55' : '1',
        })},_vm._l((_vm.workHours),function(hour){return _c('div',{key:("date_" + (_vm.columnItem.date.getDate()) + "_cell_" + hour),staticClass:"column__cell",style:({
                height: ((_vm.ceilSize.height) + "px"),
            })})}),0),_c('div',{staticClass:"working-time",style:({
            'height' : ("calc(100% - " + _vm.workTimeTopHeight + " - " + _vm.workTimeBotHeight + ")"),
            'margin-top': _vm.workTimeTopHeight,
            'margin-bottom': _vm.workTimeBotHeight,
        }),attrs:{"id":'working-time-' + _vm.columnItem.date.getDate()}},[(_vm.columnIsInQuestion)?_c('QuestionDate',{attrs:{"workStart":_vm.workStart,"ceilHeight":_vm.ceilSize.height}}):_vm._e(),_vm._l((_vm.training),function(slot,index){return _c('Card',{key:("card-" + index),attrs:{"eventSlot":slot,"position":slot.getPosition(_vm.workStart, _vm.ceilSize.height),"classification":_vm.classification,"isSelected":_vm.isSelected,"date":_vm.columnItem.date}})}),_vm._l((_vm.slots),function(slot,index){return _c('NewCard',{key:("new-card-" + index + "-" + (slot.index)),attrs:{"isSelected":_vm.isSelected,"isCompareMode":_vm.tableMode.isCompare,"isMovable":_vm.tableMode.isPlanning,"index":slot.period.index || slot.id,"eventSlot":slot,"movementStep":_vm.movementStep,"replaceableSlot":_vm.replaceableSlot}})}),_vm._l((_vm.compareSlots),function(slot){return _c('CompareCard',{key:("compare-card-" + (slot.index)),attrs:{"eventSlot":slot,"isSelected":_vm.isSelected}})})],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }