<template>
    <div class="filter-type-cont">

        <div class="filter-container default" v-if="isManager">

            <ChangeMode :isPlanningMode="tableMode.isPlanning"/>
            <ChangeColumnCount/>
            
            <div class="filter-container" v-show="!tableMode.isPlanning">
                <Today/>
                <ChangeDate :currentDay="currentDay"/>
                <CompareCalendar v-if="!tableMode.Compare"/>
            </div>
        
            <div class="filter-container" v-show="tableMode.isPlanning">
                <KeepAlive> 
                    <ChangeMovementStep/>
                </KeepAlive>
                <KeepAlive>
                    <ChangeMovementAction/>
                </KeepAlive>
            </div>
        </div>

        <div class="filter-container" v-else>
            <ChangeColumnCount/>
            <Today/>
            <ChangeDate :currentDay="currentDay"/>
            <CompareCalendar v-if="!tableMode.Compare"/>
        </div>
    </div>
</template>

<script>
import Item from './Item.vue'
import Tool from './Tool.vue'
import Today from './Today.vue'
import ChangeMode from './ChangeMode.vue'
import ChangeDate from './ChangeDate.vue'
import CompareCalendar from './CompareCalendars.vue'
import ChangeColumnCount from './ChangeColumnCount.vue'

import ChangeMovementStep from './ChangeMovementStep.vue'
import ChangeMovementAction from './ChangeMovementAction.vue'

import { ADMIN_MEMBERSHIP_LEVEL, MANAGER_MEMBERSHIP_LEVEL, OWNER_MEMBERSHIP_LEVEL } from '../../../scripts/membershipLevels'

export default {
    name: 'Filt',
    props:{
        currentDay: Date,
        role: String,
    },
    computed: {
        tableMode(){
            return this.$store.getters.tableMode
        },
        isManager(){
            let membershipLevel = this.$store.getters.getMembershipLevel
            if (membershipLevel == -1) return false

            return [
                MANAGER_MEMBERSHIP_LEVEL,
                ADMIN_MEMBERSHIP_LEVEL,
                OWNER_MEMBERSHIP_LEVEL,
            ].includes(membershipLevel)
        }
    },
    components:{
        Item,
        Tool,
        Today,
        ChangeMode,
        ChangeDate,
        ChangeColumnCount,

        ChangeMovementStep,
        ChangeMovementAction,
        CompareCalendar
    },
    data(){
        return{
            filterItems:[
                'Без фильтра',
                'Учителя',
                'Типы',
                'Сложность',
            ],
        }
    },
}

</script>

<style scoped>
.filter-type-cont{
    padding: 10px 0 10px 30px;
    min-width: 300px;
    position: sticky;
    height: 51px;
    background: white;
    z-index: 4;
    top: 70px; /* header + small-about */
}
.filter-container{
    display: flex;
    gap: 10px;
}

.filter-container .filter-item:first-child{
    border-radius: 8px 0px 0px 8px;
}

.filter-container .filter-item:last-child{
    border-radius: 0px 8px 8px 0px;
}

.filter-container .filter-item:only-child{
    border-radius: 8px
}

</style>