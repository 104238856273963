var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about",class:{
        swiped: _vm.isSwiped
    }},[_c('div',{attrs:{"id":_vm.isSwiped ? 'small-about' : 'big-about'}},[_c('div',{staticClass:"avatar-cont",class:{
                'big': !_vm.isSwiped,
                'small': _vm.isSwiped,
            }},[_c('img',{attrs:{"src":require("@/assets/yoga.jpg"),"id":"avatar"},on:{"click":function($event){return _vm.changeMode(!_vm.isSwiped)}}}),(!_vm.isSwiped)?_c('div',{staticClass:"star-cont",style:({
                    'color': _vm.isFavorite? '#F9DC5C':'#969696'
                }),on:{"click":_vm.setFavorite}},[_c('span',{staticClass:"iconify",attrs:{"data-icon":"ant-design:star-filled","data-width":"24","data-height":"24"}})]):_vm._e()]),_c('div',{class:_vm.isSwiped ? 'small-info' : 'big-info'},[_c('div',{staticClass:"header__cont"},[_c('div',{staticClass:"header__title",on:{"click":function($event){return _vm.changeMode(!_vm.isSwiped)}}},[_vm._v(" "+_vm._s(_vm.calendarRestricted ? _vm.calendarRestricted.title : _vm.calendarTitle)+" ")]),_c('div',{staticClass:"timezone"},[_vm._v(" "+_vm._s(_vm.timezone)+" ")])]),(!_vm.isSwiped)?_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.calendarDescription)+" ")]):_vm._e(),_c('div',{staticClass:"social__cont"},_vm._l((_vm.calendarSocialLinks),function(link,key){return _c('div',{key:("social_link_" + key)},[_c('a',{attrs:{"href":link}},[_c('span',{staticClass:"iconify",attrs:{"data-icon":_vm.icons[key],"data-width":"20","data-height":"20"}})])])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }