import { render, staticRenderFns } from "./ChangeColumnCount.vue?vue&type=template&id=eb39f714&scoped=true&"
import script from "./ChangeColumnCount.vue?vue&type=script&lang=js&"
export * from "./ChangeColumnCount.vue?vue&type=script&lang=js&"
import style0 from "./ChangeColumnCount.vue?vue&type=style&index=0&id=eb39f714&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb39f714",
  null
  
)

export default component.exports