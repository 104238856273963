<template>
    <div class="calendar-column"
    v-if="columnItem.scale > 0.2"
    v-bind:class="{
        selected: isSelected,
    }"
    v-bind:style="{ 
        width: getColumnWidth(isSelected) * columnItem.scale + 'px',
        opacity: columnItem.scale,
        background: columnColor(),
    }"
    :id="'calendar-column-' + columnItem.date.getDate()"
    :ref="'calendar-column-' + columnItem.date.getDate()">

        <!-- Background ceils -->
        <div class="column_cell_cont"
            v-bind:style="{
                background: columnIsInQuestion && !questionDetails.isNearestPlace ? questionDetails.color : '',
                opacity: columnIsInQuestion ? '0.55' : '1',
            }">
            <!-- <div v-if="columnScale < 1">
                {{ (getColumnWidth(columnCount, isSelected) * columnScale).toFixed(1) }}
            </div> -->
            <div class="column__cell"
                v-for="hour in workHours"
                :key="`date_${columnItem.date.getDate()}_cell_${hour}`"
                v-bind:style="{
                    height: `${ceilSize.height}px`,
                }">
            </div>
        </div>

        <!-- Work time -->
        <div class="working-time" 
            :id="'working-time-' + columnItem.date.getDate()"
            v-bind:style="{
                'height' : `calc(100% - ${workTimeTopHeight} - ${workTimeBotHeight})`,
                'margin-top': workTimeTopHeight,
                'margin-bottom': workTimeBotHeight,
            }">
            <!-- <CreatePlace
                v-if="isSelected && 
                    !slotIsChangePlace &&
                    tableMode.isPlanning && 
                    !tableMode.isChangePlace &&
                    createNewSlotPosition != undefined"
                :position="createNewSlotPosition"
            /> -->
            <QuestionDate
                v-if="columnIsInQuestion"
                :workStart="workStart"
                :ceilHeight="ceilSize.height"
            />
            <Card
                v-for="(slot,index) in training"
                :key="`card-${index}`"
                :eventSlot="slot"
                :position="slot.getPosition(workStart, ceilSize.height)"
                :classification="classification"
                :isSelected="isSelected"
                :date="columnItem.date"
            />
            <NewCard
                v-for="slot, index in slots"
                :key="`new-card-${index}-${slot.index}`"
                :isSelected="isSelected"
                :isCompareMode="tableMode.isCompare"
                :isMovable="tableMode.isPlanning"
                :index="slot.period.index || slot.id"
                :eventSlot="slot" 
                :movementStep="movementStep"
                :replaceableSlot="replaceableSlot"
            />
            <CompareCard
                v-for="slot in compareSlots"
                :key="`compare-card-${slot.index}`"
                :eventSlot="slot"
                :isSelected="isSelected"
            />
        </div>
    </div>
</template>

<script>
import Card from './Card.vue'
import Slot from '../../../scripts/slot'
import AlertMixin from './mixins/AlertMixin.vue'
import DateTimeMixin from './mixins/DateTimeMixin.vue'

import { bus } from '../EventBus.js'

import TimePeriod from '../../../scripts/timePeriod'
import uuid4 from '../../../scripts/uuid'
import Vue from 'vue'
import { cardPosition } from '../../../scripts/cardPosition';
import Alert from '../../../scripts/alert'
import CalendarHeaderItem from '../../../scripts/calendarHeaderItem'
import { UpdateSlotTime } from '../../../scripts/slot'
import { dateIsPast } from '../../../scripts/date'
import pastDateColor from '../../../scripts/pastDateColor'

export default {
    name: "Column",
    props: {
        columnItem: CalendarHeaderItem,
        isSelected: Boolean,
        slotIsChangePlace: Boolean,
        training: {
            type: Array,
            default: () => []
        },
        tableMode: Object,
        classification: Object,
        movementStep: Number,
        border: Object,
        replaceableSlot: [Object, null],
    },
    data(){
        return {
            nearestSlotPlace: undefined,
            addedSlotsCount: 0,
            lastPlacePos: 0,
            createPlace: {},
            createMutex: false,
            isRendered: false,
            comparableSlotsInMove: {
                compareSlots: {}, // from another calendar
                slots: {}, // from current calendar
            },
            pastDateColor:pastDateColor,
        }  
    },
    computed: {
        currentScroll(){
            return this.$store.getters.currentScroll
        },
        createNewSlotPosition(){               
            let place = this.getCreatePlace()
            
            if (!place) return undefined

            place = place.start.date
            return this.getPosition( place || -1, this.workStart, this.ceilSize.height)
        },
        slots:{
            get(){
                this.addedSlotsCount
                return this.$store.getters.calendarSlotsByDate(this.columnItem.date)
            },
            set(){
                this.addedSlotsCount += 1
            }
        },
        ceilSize(){
            return this.$store.getters.tableCeilSize
        },
        workHours(){
            return this.$store.getters.calendarWorkHours
        },
        workEnd(){
            return this.$store.getters.calendarWorkEnd
        },
        workStart(){
            return this.$store.getters.calendarWorkStart
        },
        columnCount(){
            return this.$store.getters.tableHeader.columnCount
        },
        workTimeTopHeight(){
            if (!this.workStart) return
            return this.workStart.minute / 60 * 54 + 'px'
        },
        workTimeBotHeight(){
            if (!this.workEnd) return
            if (this.workEnd.minute == 0) return '0px'

            return (1 - this.workEnd.minute / 60) * 54  + 'px'
        },
        questionDetails(){
            return this.$store.getters.tableQuestionPlace
        },
        columnIsInQuestion(){
            let columnId = this.columnItem.date.getDate()
            return this.$store.getters.tableCheckQuestionColumn(columnId)
        },
        compareSlots(){
            if (!this.columnItem.date) return []
            return this.$store.getters.compareSlotsByDate(this.columnItem.date)
        }
    },
    mixins: [
        AlertMixin,
        DateTimeMixin
    ],
    components:{
        Card,

        NewCard : () => import('./NewCard.vue'),
        LastPlace : () => import('./LastPlace.vue'),
        CreatePlace : () => import('./CreatePlace.vue'),
        CompareCard : () => import('./CompareCard.vue'),
        QuestionDate : () => import('./QuestionDate.vue'),
    },

    mounted() {
        let columnIndex = this.columnItem.date.getDate()
        bus.$on('column-' + columnIndex, 'create-slot', this.createSlot)
        bus.$on('column-' + columnIndex, 'delete-slot', this.deleteSlot)
        bus.$on('column-' + columnIndex, 'update-new-slot-count', () => this.addedSlotsCount += 1)
        bus.$on('column-' + columnIndex, 'check-busy-place', ({period,nearest}) => this.placeIsBusy(period, nearest))
        
        bus.$on('column-' + columnIndex, 'setup-compare-slots', this.setupCompareSlots)
        bus.$on('column-' + columnIndex, 'check-compare-slots', this.checkCompareSlots)
        bus.$on('column-' + columnIndex, 'clear-compare-slots', this.clearCompareSlots)

        bus.$on('column-' + columnIndex, 'update-slot-last-date', this.updateSlotLastDate)
        bus.$on('column-' + columnIndex, 'insert-slot-into-group', this.insertCard)
    },
    updated() {
        // console.log(`update column ${this.columnItem.date}`)
        let columnIndex = this.columnItem.date.getDate()
        if (!bus.checkType('column-' + columnIndex)){
            bus.$on('column-' + columnIndex, 'create-slot', this.createSlot)
            bus.$on('column-' + columnIndex, 'delete-slot', this.deleteSlot)
            bus.$on('column-' + columnIndex, 'update-new-slot-count', () => this.addedSlotsCount += 1)
            bus.$on('column-' + columnIndex, 'check-busy-place', ({period,nearest}) => this.placeIsBusy(period, nearest))
            
            bus.$on('column-' + columnIndex, 'setup-compare-slots', this.setupCompareSlots)
            bus.$on('column-' + columnIndex, 'check-compare-slots', this.checkCompareSlots)
            bus.$on('column-' + columnIndex, 'clear-compare-slots', this.clearCompareSlots)

            bus.$on('column-' + columnIndex, 'update-slot-last-date', this.updateSlotLastDate)
            bus.$on('column-' + columnIndex, 'insert-slot-into-group', this.insertCard)
        }
    },
    beforeDestroy() {
        let columnIndex = this.columnItem.date.getDate()
        // let tableHeader = this.$store.getters.tableHeader
        // if (!tableHeader.includes(this.columnItem.date))
        bus.$off('column-' + columnIndex)
    },
    methods:{
        uuid4:uuid4,
        columnColor(){

            let date = new Date(this.columnItem.date)
            let workEnd = this.$store.getters.calendarWorkEnd
            if (!workEnd)
                return (null);
            date.setHours(workEnd.hour, workEnd.minute)

            return dateIsPast(date) ? pastDateColor : null
        },
        getPosition: cardPosition,
        getColumnWidth(isSelected) {
            let ceilSize = this.$store.getters.tableCeilSize
            if (isSelected) {
                return ceilSize.selectedWidth
            } else {
                return ceilSize.defaultWidth
            }
        },

        getCreatePlace(){
            let index = -1
            let date = new Date(this.columnItem.date.getTime()) 
            let now = new Date()
            date.setHours(this.workStart.hour, this.workStart.minute)

            let freePeriod = new TimePeriod(date, index)

            let placeInScreen = this.currentScroll
            let newDate = new Date(date.getTime())

            if (placeInScreen != 0) {
                newDate = this.getPlaceInScreen(placeInScreen, date)
            }
            if (newDate.getTime() < now.getTime()) {
                newDate.setHours(now.getHours(), now.getMinutes())
            }
            
            this.minuteRound(newDate)
            let period = new TimePeriod(newDate, index)
            let result = this.placeIsBusy(period, {toTop: false}, true)
            console.log('result', result)

            // 1. Place is not busy
            if (result !== undefined && !result.busy) {
                freePeriod.update(newDate) 
            }
            // 2. Place is busy, but nearest place is exists
            else if (result.busy && result.nearest.place) {
                freePeriod.update(result.nearest.place) 
            }
            // 3. Place is busy and nearest place is undefined
            else if (result.busy && result.nearest.place === undefined) {
                return undefined
            }
            // 4. Place on top of screen
            else {
                // TODO: add scrollTo
                date.setHours(now.getHours(), now.getMinutes())
                this.minuteRound(date)

                let period = new TimePeriod(date, index)

                freePeriod = this.getFreePlace(period, false, true, true)
            }

            this.createPlace = freePeriod

            return freePeriod
        },
        getPlaceInScreen(pageY, date){
            
            let position = pageY/this.ceilSize.height // current position

            let hour = this.workHours[Math.trunc(position)] // current item in the table
            let minute = (position % 1) * 60// position in the item
            
            let newDate = new Date(date.getTime())
            newDate.setHours(hour, minute)


            return newDate
        },
        
        createSlot(){
            let calendarUid = this.$route.params.calendarUid
            let freePeriod = this.createPlace
            if (!freePeriod){
                console.log('freePeriod', freePeriod)
                Alert.fire('Неудалось найти свободное место.')
                return
            }

            if (this.createMutex) return

            this.createMutex = true
            this.$store.dispatch('create-slot', {calendarUid, freePeriod}).then((newSlots) => {
                let slots = {
                    ...this.slots,
                    ...newSlots,
                }

                this.slots = slots
                this.createMutex = false
            })
            
        },

        deleteSlot({slot}){
            this.$store.dispatch('delete-slot', {slot}).then(() => {
                this.$delete(this.slots, slot.index)
            })
        },
        updateSlotLastDate({lastDate}){
            if(this.tableMode.isChangeColumn){
                this.lastPlacePos = this.getPosition(lastDate, this.workStart, this.ceilSize.height)
            }   
        },
        checkIntersection(trainingPeriod, newPeriod, isCreate){
            // Training date
            let start = newPeriod.start.date
            let end   = newPeriod.end.date

            let strictly = isCreate

            let onBorder = {
                top: trainingPeriod.include(start, strictly),
                bot: trainingPeriod.include(end, strictly)
            }
            let isEquals = trainingPeriod.equals(newPeriod)

            let isBusy = onBorder.top || onBorder.bot || isEquals || newPeriod.intersection(trainingPeriod, strictly)

            let goTop
            let hour = trainingPeriod.hour()
            let minute = trainingPeriod.minute()

            if (isBusy) {
                goTop = newPeriod.start.time - trainingPeriod.start.time < trainingPeriod.end.time - newPeriod.start.time
                
                // Equals with word start
                if (hour == this.workStart.hour && minute == this.workStart.minute) {
                    goTop = false
                }

                // Equals with word end
                if (hour == this.workEnd.hour && minute == this.workEnd.minute) {
                    goTop = true
                }
            } 

            return {isBusy:isBusy,goTop:goTop} 
        },
        getFreePlace(slotPeriod, goTop, isCreate, isSecondTry=false){
            
            // Get nearest free place for new card
            let busy = true
            let now = (new Date()).getTime()
            let resultPeriod = slotPeriod.copy()

            const border = this.border
            let beenOnTopBorder = false

            const searchVectorTop = goTop
            const searchVectorStep = goTop?-1:1

            while (busy){
                resultPeriod.shift(searchVectorStep) // shift by minutes to/from top
                // console.log('-- test date', resultPeriod.start.date)

                // If intersection with no working time
                let top = {
                    endIntersection : resultPeriod.include(border.top.end.date),
                    startIntersection : resultPeriod.include(border.top.start.date),
                }
                let bot = {
                    endIntersection : resultPeriod.include(border.bot.end.date),
                    startIntersection : resultPeriod.include(border.bot.start.date),
                }

                let dateIsChanged = resultPeriod.date() != this.columnItem.date.getDate()

                // Spin the changeVector on 180 deg or return undefined 
                if ( isCreate && ( top.endIntersection ||
                                   bot.startIntersection ||  
                                   dateIsChanged )) {
                    if (isSecondTry) return undefined 

                    return this.getFreePlace( resultPeriod, !searchVectorTop, isCreate, true)
                }

                if ( !isCreate && ( top.startIntersection ||
                                    bot.endIntersection ||  
                                    dateIsChanged )) {
                    if (isSecondTry) return undefined 

                    return this.getFreePlace( resultPeriod, !searchVectorTop, isCreate, true)
                }


                // If resultPeriod intersects with borders
                let onTop = border.top.intersection(resultPeriod, isCreate)
                let onBot = border.bot.intersection(resultPeriod, isCreate)
                let onBorders = onTop || onBot
                if (onBorders) {
                    beenOnTopBorder = onTop
                    continue
                }

                // If the time interval is less than the present time
                if (resultPeriod.start.time < now) continue

                let busyPlaces = {
                    new : Object.values(this.slots).filter( slot => {
                        let period = slot.period
                        if (resultPeriod.index === period.index) return false

                        // let result = this.checkIntersection(period, resultPeriod, isCreate)
                        let result = this.checkIntersection(period, resultPeriod, isCreate)
                        // console.log(`       test ${slot.index}`)
                        // console.log(`       test ${period.toString()}`)
                        return result.isBusy
                    })
                }

                // If resultPeriod intersects with other working periods
                busy = busyPlaces.new.length > 0
            }

            if (beenOnTopBorder){
                resultPeriod.shift(1)
            }

            return resultPeriod
        },
        updateSlotBusyness({busy, nearest, intersection}, index, messageId, slotStart){

            if (index == -1) {
                bus.$emit('create-slot', 'update-nearest', {
                    nearest,
                    intersection,
                    busy,
                })
                return
            }
            
            let slot = this.$store.getters.getSlotByIndex(index)
            slot.updateBusyness({
                isBusy: busy,
                nearestDate: nearest,
            })
            slot.updateIntersection(slotStart, intersection)
            this.nearestSlotPlace = nearest

            let cause = {
                1: {title: "on border of the work time"},
                2: {title: "time is past"},
                3: {title: "column has no cards"},
                4: {title: "is no intersection"},
                5: {title: "place is busy, go to nearest"},
                6: {title: "this day is a holyday"},
                7: {title: "insert is done"}
            }

            let method = cause[messageId]
            console.log(`place-is-busy: ${busy} : ${method.title} : ${nearest?nearest.place:""} ` )
        },
        placeIsBusy(period, nearest, isCreate=false) {
            // Check of place for the card is busy or not
            // Desired date
            const border = this.border
            const origin = period.index
            let now = (new Date()).getTime()

            let currentPeriod = period
        
            console.log(`place-is-busy: period : ${currentPeriod.toString()}`)

            // 1. Check intersection with borders
            let onTop = border.top.include(currentPeriod.start.date, isCreate)
            let onBot = border.bot.include(currentPeriod.end.date, isCreate)
            let onBorders = onTop || onBot            
            if(onBorders) {
                let searchVectorTop = onBot
                let freePeriod = this.getFreePlace(currentPeriod, searchVectorTop, isCreate)
                // If column have free date
                if (freePeriod) {
                    nearest.place = freePeriod.start.date
                    console.log('free period', freePeriod.start.date)
                } 
                
                let result = { busy: true, nearest: nearest}
                if (!isCreate) this.updateSlotBusyness(result, origin, 1, period.start.date)

                return result   
            }

            // 2. Check current time ( the card will check it by itself)
            if (isCreate && now > currentPeriod.start.time ){
                let result = { busy: true, nearest: nearest }
                if (!isCreate) this.updateSlotBusyness(result, origin, 2, period.start.date)
                
                return result
            }
                

            // 3. Check new and old trainings
            if (Object.keys(this.slots).length == 0 && this.training.length == 0){
                let result = { busy: false }
                if (!isCreate) this.updateSlotBusyness(result, origin, 3, period.start.date)

                return result
            }
                
            let toTop

            // Check busy times
            let busyPlaces = {  
                new : Object.values(this.slots).filter( slot => {
                    let trainingPeriod = slot.period
                    if (currentPeriod.index === trainingPeriod.index) {
                        return false
                    }
                    let result = this.checkIntersection(trainingPeriod, currentPeriod, true)
                    if ( result.isBusy ) toTop = result.goTop
                    return result.isBusy
                }) ,
                old : this.training.filter( elem => {
                    let result = this.checkIntersection(new TimePeriod(elem.time), currentPeriod, isCreate)
                    return result.isBusy
                })
            }
            
            console.log('create-slot:busyPlaces', busyPlaces)
            if(busyPlaces.new.length == 0 && busyPlaces.old.length == 0){
                let result = { busy: false }
                if (!isCreate) this.updateSlotBusyness(result, origin, 4, period.start.date)
                return result
            }


            // If busy with this time was found
            if (!nearest.place || toTop !== nearest.toTop ) {

                if (!isCreate) nearest.toTop = toTop

                let freePeriod = this.getFreePlace( currentPeriod, nearest.toTop, isCreate)

                // If column have free date
                if (freePeriod) {
                    nearest.place = freePeriod.start.date
                    console.info(`place-is-busy: freePeriod : ${freePeriod.toString()}`)
                }   
            }

            let result = { 
                busy: true, 
                nearest: nearest,
                intersection: {
                    newSlots: busyPlaces.new,
                    oldSlots: busyPlaces.old,
                }
            }
            
            if (!isCreate) {
                this.updateSlotBusyness(result, origin, 5, period.start.date)
            }

            return result 
        },

        setupCompareSlots(){
            this.$set(this.comparableSlotsInMove, 'compareSlots', {})
            this.$set(this.comparableSlotsInMove, 'slots', {})
            Object.keys(this.slots).forEach(slotIndex => this.checkCompareSlots({slotIndex})) 
        },

        checkCompareSlots({slotIndex, period}){
            const slot = this.slots[slotIndex]
            if (!slot) return

            let slotPeriod 
            if (period){
                slotPeriod = period
            } else {
                slotPeriod = slot.period
            }            

            // Find compare slots from another calendar that intersect with the current slot
            let compareSlotsIndices = Object.values(this.compareSlots).filter(compareSlot => {
                if (slotIndex === compareSlot.index) {
                    return false
                }
                let result = this.checkIntersection(slotPeriod, compareSlot.period, true)
                return result.isBusy
            }).map(compareSlot => compareSlot.index)
            
            let compareSlotsIsFound = compareSlotsIndices.length > 0

            console.log(`check-compare-places: 
                compareSlotsIsFound: ${compareSlotsIsFound}
                compareSlotsIndices: ${compareSlotsIndices}`)

            this.updateCompareSlots(compareSlotsIndices, slotIndex)

            // Compare slots is found
            if (compareSlotsIsFound) {
                
                // Update intersection data about compare slots
                this.slots[slotIndex].compare.items = compareSlotsIndices

                // Toggle current slot to the compare mode once

                let compareSlotIsRecorded = this.comparableSlotsInMove.slots[slotIndex]
                if (compareSlotIsRecorded) return

                this.slots[slotIndex].setCompareWithAnimation(true)
                this.$set(this.comparableSlotsInMove.slots, slotIndex, true)
            } else {

                // Toggle current slot to the no compare mode once

                let comparableNewCardIsExists = this.comparableSlotsInMove.slots[slotIndex]
                if (!comparableNewCardIsExists) return

                this.slots[slotIndex].compare.items = []
                this.slots[slotIndex].setCompareWithAnimation(false)
                this.$delete(this.comparableSlotsInMove.slots, slotIndex)
            }            
        },

        updateCompareSlots(newCompareSlotsIndices, slotIndex){
            // slot --> compare-slot
            //     \---> compare-slot

            let oldCompareSlotsIndices = this.comparableSlotsInMove.compareSlots[slotIndex]
            
            if (oldCompareSlotsIndices){
                // Close compare mode for old compare slots that already have no intersection with current slot
                oldCompareSlotsIndices.forEach(compareSlotIndex => {
                    // If compare slot record is already using
                    if (newCompareSlotsIndices.includes(compareSlotIndex)) return

                    // Check compare with another slots
                    let anotherSlots = Object.keys(this.comparableSlotsInMove.compareSlots)
                        .filter(index => index != slotIndex)
                        .map(index => this.comparableSlotsInMove.compareSlots[index])
                    let compareIsExists = anotherSlots.find((compareSlots) => {
                        return compareSlots.includes(compareSlotIndex)
                    })
                    if (compareIsExists) return

                    // Same logic like the slot from checkCompareSlots
                    let compareSlotIndexToRemove = this.comparableSlotsInMove.compareSlots[slotIndex].findIndex((slotIndex) => {
                        return slotIndex == compareSlotIndex
                    })
                    this.compareSlots[compareSlotIndex].setCompareWithAnimation(false)
                    this.$delete(this.comparableSlotsInMove.compareSlots[slotIndex], compareSlotIndexToRemove)
                })
            }
            
            // Open compare mode for new compare slots that not contain in the old
            newCompareSlotsIndices.forEach(compareSlotIndex => {
                // If compare slot record is already using
                if (oldCompareSlotsIndices && oldCompareSlotsIndices.includes(compareSlotIndex)) return

                // Same logic like slot from checkCompareSlots
                if (!this.comparableSlotsInMove.compareSlots[slotIndex]) {
                    this.comparableSlotsInMove.compareSlots[slotIndex] = []
                }

                // Add to cache and set compare
                this.comparableSlotsInMove.compareSlots[slotIndex]
                this.compareSlots[compareSlotIndex].setCompareWithAnimation(true)
                let length = this.comparableSlotsInMove.compareSlots[slotIndex].length
                this.$set(this.comparableSlotsInMove.compareSlots[slotIndex], length, compareSlotIndex)
            })
        }, 
        
        clearCompareSlots(){
            Object.values(this.slots).forEach(slot => slot.setCompareWithAnimation(false))
            this.comparableSlotsInMove.slots = {}
            this.comparableSlotsInMove.compareSlots = {}
        },

        columnWorkTimePeriod(){
            let start = new Date(this.columnItem.date.getTime())
            let workStart = this.$store.getters.calendarWorkStart
            start.setHours(workStart.hour, workStart.minute)
            
            let end = new Date(this.columnItem.date.getTime())
            let workEnd = this.$store.getters.calendarWorkEnd
            end.setHours(workEnd.hour, workEnd.minute)

            return new TimePeriod(start, -1, 0, end)
        },

        insertCard({date, currentIndex, nextIndex}){
            

            let workTimePeriod = this.columnWorkTimePeriod()
            let slot = this.$store.getters.getSlotByIndex(currentIndex)
            let nextSlot = this.$store.getters.getSlotByIndex(nextIndex)
            let movementHistory = [new UpdateSlotTime(slot, nextSlot.period.start.date)]
            
            // 1. Check the insert is possible
            let isPossible = true
            let currentSlotPeriod

            while (true){
                
                // Shift the slot period copy   
                slot = this.slots[nextIndex]
                currentSlotPeriod = slot.period.copy()
                currentSlotPeriod.shift(slot.period.duration)

                // If after shift the slot going thought the work time
                
                if (!workTimePeriod.include(currentSlotPeriod.start.date) ||
                    !workTimePeriod.include(currentSlotPeriod.end.date))
                {
                    isPossible = false
                    break
                }

                movementHistory.push(new UpdateSlotTime(slot, currentSlotPeriod.start.date))
                
                // Check intersection and the busyness
                let result = this.placeIsBusy(currentSlotPeriod, { place: null, toTop: false}, false)
                if (!result.busy)
                    break

                // Find intersection (always 1 card), go to next
                let inter = Object.values(result.intersection.newSlots)
                nextIndex = inter[0].index

                if (nextIndex == currentIndex){
                    if (inter.length <= 1) break

                    nextIndex = inter[1].index
                }
            }

            if (!isPossible){
                bus.$emit(`new-card-${currentIndex}`, 'move-to-last-place')
                movementHistory.forEach(slotDate => slotDate.slot.clearState())
                return 
            }

            // s. Move all slots
            this.$store.dispatch('update-multi-slot-time', {
                slotDateArray: movementHistory
            }).catch(data => {
                bus.$emit(`new-card-${currentIndex}`, 'move-to-last-place')
                movementHistory.forEach(slotDate => slotDate.slot.clearState())
            })
        }
    }
}
</script>

<style>

.calendar-column:last-child > .column_cell_cont > .supported__cell{
    border-right: 1px solid grey;
}

.calendar-column:last-child > .calendar-card{
    border-right: 1px solid grey;
}
</style>

<style scoped>

.column_cell_cont{
    display: flex;
    flex-direction: column;
}

.column_cell_cont > * + * {
    border-top: 1px solid grey;
}

.calendar-column{
    position: relative;
    transition: width .2s;
}

.holiday{
    background: #F8E9E8;
}
.selected{
    min-width: 50px;
}

.working-time{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
}
</style>