function smoothScroll(targetPosition, component) {

    component.scrollTo({
        top: targetPosition,
        behavior: 'smooth'
    });

    return new Promise((resolve, reject) => {
        const failed = setTimeout(() => {
            reject();
        }, 2000);

        const scrollHandler = () => {
            if (component.scrollTop === targetPosition) {
                component.removeEventListener("scroll", scrollHandler);
                clearTimeout(failed);
                resolve();
            }
        };
        if (component.scrollTop === targetPosition) {
            clearTimeout(failed);
            resolve();
        } else {
            component.addEventListener("scroll", scrollHandler);
        }
    });
}

export default smoothScroll