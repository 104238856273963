<template>
    <div if="calendar-list">
        <div class="error">
            {{errorText}}
        </div>
    </div>
</template>

<script>
export default {
    name: 'CalendarError',
    props: {
        errorText: {
            type: String,
            default: "",
        }
    },
    data(){
        return {
            calendars: []
        }
    }
}
</script>

<style scoped>
.error{
    display: flex;
    justify-content: center;
    margin-top:70px;
    
}

</style>