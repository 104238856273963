<template>
    <div 
	id="create-slot"
	class="create-slot"
	@dragstart="() => false"
	v-bind:style="{
		top			: `${posY}px`,
		left 		: `${posX}px`,
		width		: `${getColumnWidth(true)}px`,
		display		: 'none',
		color		: slotToCopy ? slotToCopy.settings.cellTextColor : null,
		background	: slotToCopy ? slotToCopy.settings.cellBackgroundColor : null,
	}"
	v-bind:class="{
		draggable: dragging,
	}">
		<div class="create-slot__header">
			<div>{{ slotToCopy && slotToCopy.title ? slotToCopy.title : "New slot" }}</div>
			<div>{{getTimeString()}}</div>
		</div>
    </div>
</template>

<script>
import Alert from '../../../scripts/alert';
import { convertTZ, dateIsPast, pair, UTCString } from '../../../scripts/date';
import Slot from '../../../scripts/slot';
import TimePeriod from '../../../scripts/timePeriod';
import CMS from '../../../service/cms/service';
import { bus } from '../EventBus';
import AirDatepicker from 'air-datepicker';

function eventToCoordinates(event) { 
	return {x: event.clientX, y: event.clientY}; 
}

const CHANGE_COLUMN_DISTANCE = 8
let createSlotId = 0
function getCreateSlotId() {
	createSlotId -= 1
	return createSlotId
}

export default {
	name: 'CreateSlot',	
	mounted(){
		window.navigator.vibrate(65)
		this.makeDraggable(this.$el)
		bus.$on('create-slot', 'update-nearest', (data) => {
			console.log('data :>> ', data);

			let {nearest, intersection, busy} = data

			if(!busy){
				this.isBusy = false
				this.nearest = {}
				this.insertIndex = undefined
				return
			}

			this.isBusy = busy
			this.nearest = nearest
			this.intersection = intersection
		})
	},
	props: {
		testEvent: PointerEvent,
		replaceableSlot: [Object, null],
	},
	computed: {
		workStart(){
			return this.$store.getters.calendarWorkStart
		},
		workEnd(){
			return this.$store.getters.calendarWorkEnd
		},
        ceilSize(){
            return this.$store.getters.tableCeilSize
        },
		slotToCopy(){
			return this.$store.getters.tableSlotToCopy
		}
	},
	data() {
		return {
			posX: 0,
			posY: 0,
			width: 0,
			tableWidth: 0,
			tableRightSide: null,
			tableLeftSide: null,
			tableSelectedColumn: null,
			tableHeight: 0,
			tableHeightTopBorder: 0,
			dragging: null, 
			futureColumn: null,
			// index: getCreateSlotId(),
			placeIsPast: false,
			
			hour: 0,
			minute: 0,

			nearest: {
				place: null,
				toTop: false,
			},
			intersection: null,
			isPreparationForDelete: false,
		};
	},
	methods: {
		getColumnWidth(isSelected) {
            let ceilSize = this.$store.getters.tableCeilSize
            if (isSelected) {
                return ceilSize.selectedWidth
            } else {
                return ceilSize.defaultWidth
            }
        },
		getTimeString(){
			return `${pair(this.hour)}:${pair(this.minute)}`
		},
		initTableColumnWidths(){
			let start = 0
			let tableHeader = this.$store.getters.tableHeader
			let selectedItem = tableHeader.selectedItem()

			let defaultWidth = this.getColumnWidth(false)
			let selectedWidth = this.getColumnWidth(true)

			this.tableLeftSide = tableHeader.leftSide().map(columnItem => {
				let item  = {
					start: start,
					end: start + defaultWidth, 
					date: columnItem.date
				}
				start = start + defaultWidth
				return item
			})

			this.tableSelectedColumn = {
				start: start,
				end: start + selectedWidth, 
				date: selectedItem.date
			}

			start = start + selectedWidth

			this.tableRightSide = tableHeader.rightSide().map(columnItem => {
				let item  = {
					start: start,
					end: start + defaultWidth, 
					date: columnItem.date
				}
				start = start + defaultWidth
				return item
			})
		},

        dateTimeRound(roundedDate) {
            let hour = roundedDate.getHours()
            let minute = roundedDate.getMinutes()
            let minuteUnit = minute % 10

            let movementStep = this.movementStep
			
            if (movementStep != 1){
                if (minuteUnit < 5 && minuteUnit != 0) {
                    roundedDate.setMinutes(movementStep - minuteUnit + minute)
                }
                if (minuteUnit >= 5) {
                    roundedDate.setMinutes(10 - minuteUnit + minute)
                }
                if (minute >= 55) {
                    roundedDate.setHours(hour + 1, 0)
                }
            }


            this.hour = roundedDate.getHours()
            this.minute = roundedDate.getMinutes()
        },

		setTimeForPosition() {
            let hour;
            let minute;
            let noWorkingBorder = this.workStart.minute / 60 * this.ceilSize.height;
            let position = (this.posY + noWorkingBorder - this.tableHeightTopBorder) / this.ceilSize.height; // current position
            hour = this.$store.getters.calendarWorkHours[Math.trunc(position)] // current item in the table
            minute = Math.round((position % 1) * 60); // position in the item
            // if hout not in workHours
            if (hour == undefined || minute < 0) {
                return;
            }
            // if last work hour
            if (hour == this.workEnd.hour && minute == this.workEnd.minute)
                return;
            if (minute > 60) {
                hour += 1;
                minute = 0;
            }

            // let checkDate = new Date(this.startDate)
           	// checkDate.setHours(hour, minute);     
			// console.log({hour, minute})     
			this.hour = hour
            this.minute = minute
            // this.dateTimeRound(checkDate);

        },

		handleStart(event){
			if (this.slotToCopy) {
				this.slotToCopy.copySlotIndex = getCreateSlotId()
			} else {
				bus.$emit('table', 'update-replaceable-slot', {index: getCreateSlotId()})
			}
			// Init table sizes
			this.$el.style.display = 'block'
			let table = document.getElementById('table')
			let tableRect = table.getBoundingClientRect()
			let elemRect = this.$el.getBoundingClientRect()

			// Table support work time for the slots that was created with old work time 
			let supportWorkTimeTop = document.getElementById("support-work-time-top")
			let supportTopHeight = supportWorkTimeTop.getBoundingClientRect().height

			let supportWorkTimeBot = document.getElementById("support-work-time-bottom")
			let supportBotHeight = supportWorkTimeBot.getBoundingClientRect().height

			this.tableHeight = Math.round(tableRect.height - elemRect.height - supportBotHeight)
			this.tableWidth = Math.round(tableRect.width - elemRect.width)
			this.tableHeightTopBorder = Math.round(supportTopHeight) 

			// Init start position of a drag and drop
			this.posX = event.pageX - 30 - elemRect.width / 2
			this.posY = event.pageY + table.scrollTop - tableRect.top - elemRect.height / 2
			this.width = elemRect.width

			// Init start point 
			let {x, y} = eventToCoordinates(event);
			this.dragging = {dx: this.posX - x, dy: this.posY - y}

			// Init width columns
			this.initTableColumnWidths()
		},

		handleMove(event){
			// console.log('event :>> ', event);
			if (!this.dragging) return;
			let {x, y} = eventToCoordinates(event);

			this.posX = Math.round(Math.max(0, Math.min(x + this.dragging.dx, this.tableWidth)))
      		this.posY = Math.round(Math.max(this.tableHeightTopBorder, Math.min(y + this.dragging.dy, this.tableHeight)))
			
			console.log("move" , `${this.posX} ${this.posY}`)
			this.setTimeForPosition()
			
			this.calcFutureColumn()
			this.checkPreparationForDelete(event.clientY)
			if (!this.futureColumn || this.isPreparationForDelete)
				return

			let date = new Date(this.futureColumn.date.getTime())
			date.setHours(this.hour, this.minute)
			this.placeIsPast = dateIsPast(date)

			this.updateQuestionColumn()
			if (this.placeIsPast)
				return

			this.updateQuestionDate()
			this.checkBusy()
		},

		
        checkPreparationForDelete(clientY){
            let deleteActiveRadius = 70
			// console.log('object :>> ', window.innerHeight , this.posY , deleteActiveRadius);
            let isInDeleteArea = window.innerHeight - clientY < deleteActiveRadius
            if (isInDeleteArea && !this.isPreparationForDelete){
                this.isPreparationForDelete = true
                this.$store.dispatch('update-question-column', {date: null})
                bus.$emit('table', 'update-slot-delete-preparation', {isDelete: true})
            }

            if (!isInDeleteArea && this.isPreparationForDelete){
                this.isPreparationForDelete = false
                bus.$emit('table', 'update-slot-delete-preparation', {isDelete: false})
            }
        },

		calcFutureColumn(){
			let slotStartX = this.posX
			let slotEndX = this.posX + this.width
			let relativeSelectedColumn = this.compareWithSelectedColumn(slotStartX, slotEndX)

		
			// Slot to the LEFT of the selected column
			if (relativeSelectedColumn == 1) {
				this.futureColumn = this.tableLeftSide.find(columnItem => {
					return this.columnIncludesPoint(columnItem, slotStartX)
				})
			} 
			// Slot to the RIGHT of the selected column
			else if (relativeSelectedColumn == -1) {
				this.futureColumn = this.tableRightSide.find(columnItem => {
					return this.columnIncludesPoint(columnItem, slotEndX)
				})
			} 
			// Slot ABOVE the selected column
			else if (relativeSelectedColumn == 0) {
				this.futureColumn = this.tableSelectedColumn
			}
			else {
				this.futureColumn = null
			}
 		},

		updateQuestionColumn(){
			if (!this.placeIsPast) {
				this.$store.dispatch('update-question-column', {
					date: this.futureColumn.date,
                    color: this.slotToCopy && this.slotToCopy.settings.cellBackgroundColor ?
						this.slotToCopy.settings.cellBackgroundColor : 
						'lavender',
                    duration: 60,
                })
			} else {
				this.$store.dispatch('update-question-column', {date:null})
			}
		},

		updateQuestionDate(){
            if (this.placeIsPast){
                this.$store.dispatch('update-question-column', {date: null})
                return
            } 

            if (this.isBusy){
                this.$store.dispatch('update-question-column', {
                    date: this.nearest.place,
                    color: this.slotToCopy && this.slotToCopy.settings.cellBackgroundColor ?
						this.slotToCopy.settings.cellBackgroundColor : 
						'lavender',
                    duration: 60,
                    isNearestPlace: true,
                })
            } else {
                this.$store.dispatch('update-question-column', {
                    date: this.futureColumn.date,
                    color: this.slotToCopy && this.slotToCopy.settings.cellBackgroundColor ?
						this.slotToCopy.settings.cellBackgroundColor : 
						'lavender',
                    duration: 60
                })
            }
		},

		checkBusy(){
			let date = new Date(this.futureColumn.date.getTime())
			date.setHours(this.hour, this.minute)

			let period = new TimePeriod(date, -1, 60)

			let columnId = date.getDate()
			bus.$emit('column-' + columnId, "check-busy-place", {
                period: period,
                nearest: this.nearest,
            });
		},

		compareWithSelectedColumn(slotStartX, slotEndX){
			let diffStart = this.tableSelectedColumn.start - slotStartX
			let diffEnd = this.tableSelectedColumn.end - slotEndX
			if (diffStart > CHANGE_COLUMN_DISTANCE && diffEnd > CHANGE_COLUMN_DISTANCE)
				return 1
			else if (diffStart < CHANGE_COLUMN_DISTANCE*-1 && diffEnd < CHANGE_COLUMN_DISTANCE*-1)
				return -1
			else 
				return 0
		},

		columnIncludesPoint(column, posX){
			return posX >= column.start && posX <= column.end
		},

		deleteSlot(){
			this.$store.dispatch('update-question-column', {date: null})
		},

		handleEnd(event){
            if (this.isPreparationForDelete)
                this.deleteSlot()
            // else if (this.eventSlot.state.insertIndex)
            //     this.pointerUpInsertIntoGroup() 
            else if (this.isBusy && this.nearest)
                this.pointerUpPlaceIsBusy()
            else if (this.placeIsPast)
                this.pointerUpPlaceIsPast() 
			else
				this.pointerUpPlaceIsFree()

			this.$store.dispatch('update-question-column', {date: null})
			this.$store.dispatch('set-test-event', null)
			bus.$emit('table', 'update-replaceable-slot', null)

			this.dragging = null
			this.posX = 0
			this.posY = 0
		},

		pointerUpPlaceIsBusy(){
			if (!this.nearest.place)
				return

			this.createSlot(this.nearest.place)
		},

		pointerUpPlaceIsFree(){
			if (!this.futureColumn)
				return
            let date = new Date(this.futureColumn.date.getTime())
			date.setHours(this.hour, this.minute)

			this.createSlot(date)
        },

		createSlot(date){
			let slotToCopy = this.$store.getters.tableSlotToCopy
			let slot
			let slotData = {}
			let calendarUid = this.$route.params.calendarUid
			let calendarTimezone = this.$store.getters.getTimezone
			let browserTimezone = this.$store.getters.browserTimezone
			if (slotToCopy){
				slot = slotToCopy.makeCopy(this.replaceableSlot.copySlotIndex)
				slotData = slot.toSubmitData()
				slot.period.update(date)
				slot.cardPeriod.update(date)
			} else {
				slot = new Slot(date, 60, {id: this.replaceableSlot.index})

				let ratings = this.$store.getters.calendarRatings
				let levels = this.$store.getters.calendarPriceLevels
				let hosts = this.$store.getters.calendarHosts

				if (ratings && ratings.length == 1)
					slotData["ratingId"] = ratings[0].id
				if (levels && levels.length == 1)
					slotData["levelId"] = levels[0].id
				if (hosts && hosts.length == 1)
					slotData["hostId"] = hosts[0].id
				
				slotData["dur"] = slot.period.duration
				slotData["timezoneId"] = calendarTimezone.id
			}

			date = convertTZ(date, calendarTimezone.name, browserTimezone.name)
			slotData["startAt"] = UTCString(date)

			this.$store.dispatch('add-slot', {slots: [slot]})
			if (!slotToCopy)
				bus.$emit('calendar', 'select-date', {date: this.futureColumn.date})
			else {
				this.slotToCopy.copySlotIndex = 0
				this.$store.dispatch('set-slot-to-copy', null)
			}
			CMS.slots.upload(calendarUid, slotData).then((slots) => {
				this.$store.dispatch('delete-slot', {slot:slot})
				if (slots.err){
                    throw slots.err
                }

                let newSlotData = slots[0]
                
                slot.index = newSlotData.id
                slot.settings = newSlotData
                slot.period.index = newSlotData.id
                slot.cardPeriod.index = newSlotData.id
                this.$store.dispatch('add-slot', {slots: [slot]})	
			})
		},

		pointerUpPlaceIsPast(){
			Alert.fire("Error : impossible to create a slot in the past tense")
		},

		makeDraggable(el) {
			el.addEventListener('pointerdown', this.handleStart);
			el.addEventListener('pointerup', this.handleEnd);
			el.addEventListener('pointercancel', this.handleEnd);
			el.addEventListener('pointermove', this.handleMove)
			el.addEventListener('touchstart', (e) => e.preventDefault());
		}
	},
};
</script>

<style scoped>
.create-slot {
	height: 54px;
	width: 100px;
	border: 1px solid grey;
	background: lavender;
	user-select: none;

	padding: 4px 3px 4px 3px;
    display: flex;
    flex-direction: column;
	font-size:12px;
}

.draggable {
	position: absolute;
	z-index: 1000;
}

.create-slot__header{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
</style>