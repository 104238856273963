var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{attrs:{"id":"table"}},[_c('div',{staticClass:"working-time-borders",attrs:{"id":"time-borders"}},[_c('div',{staticClass:"hour-border",style:({'flex' : ("0 0 " + _vm.workTimeTopHeight) })}),_c('div',{staticClass:"working-time",style:({
            height: ("calc(100% - " + _vm.workTimeTopHeight + " - " + _vm.workTimeBotHeight + " - 60px)"),
        })}),_c('div',{staticClass:"hour-border",style:({'flex' : ("0 0 " + _vm.workTimeBotHeight) })})]),(_vm.tableHeader && _vm.tableHeader.week.length > 0)?_c('div',{staticClass:"calendar__table",attrs:{"id":"calendar-table"}},[_c('SupportedWorkTime',{attrs:{"position":"top"}}),(_vm.testEvent)?_c('CreateSlot',{attrs:{"testEvent":_vm.testEvent,"replaceableSlot":_vm.replaceableSlot}}):_vm._e(),(_vm.workSpace)?_c('div',{staticClass:"column__cont"},[_vm._l((_vm.tableHeader.leftSide()),function(columnItem){return _c('Column',{key:("table-column-" + (columnItem.date.toLocaleDateString())),attrs:{"columnItem":columnItem,"border":{
                    top: _vm.workSpace.top.projection(columnItem.date),
                    bot: _vm.workSpace.bot.projection(columnItem.date)
                },"slotIsChangePlace":_vm.slotIsChangePlace,"tableMode":_vm.tableMode,"classification":_vm.classification,"movementStep":_vm.movementStep,"replaceableSlot":_vm.replaceableSlot}})}),_c('keep-alive',[_c('Column',{attrs:{"columnItem":_vm.tableSelectedItem(),"border":{
                        top: _vm.workSpace.top.projection(_vm.tableSelectedItem().date),
                        bot: _vm.workSpace.bot.projection(_vm.tableSelectedItem().date)
                    },"slotIsChangePlace":_vm.slotIsChangePlace,"tableMode":_vm.tableMode,"classification":_vm.classification,"movementStep":_vm.movementStep,"replaceableSlot":_vm.replaceableSlot,"isSelected":""}})],1),_vm._l((_vm.tableHeader.rightSide()),function(columnItem){return _c('Column',{key:("table-column-" + (columnItem.date.toLocaleDateString())),attrs:{"columnItem":columnItem,"border":{
                    top: _vm.workSpace.top.projection(columnItem.date),
                    bot: _vm.workSpace.bot.projection(columnItem.date)
                },"slotIsChangePlace":_vm.slotIsChangePlace,"tableMode":_vm.tableMode,"classification":_vm.classification,"movementStep":_vm.movementStep,"replaceableSlot":_vm.replaceableSlot}})})],2):_vm._e(),_c('SupportedWorkTime',{attrs:{"position":"bottom"}}),_c('div',{staticClass:"trash_slot_bucket_cont"},[_c('transition',{attrs:{"name":"garbage"}},[((_vm.tableMode.isPlanning && _vm.tableMode.isChangePlace) || _vm.testEvent)?_c('div',{staticClass:"bucket__border",class:{
                    preparation : _vm.isSlotDeletePreparation,
                }},[_c('span',{staticClass:"iconify",attrs:{"data-icon":"uil:trash-alt","data-width":"32","data-height":"32"}})]):_vm._e()])],1)],1):_vm._e(),(_vm.tableMode.isPlanning && _vm.isPastCalendar)?_c('div',{staticClass:"nearest_planning_date"},[_c('div',{staticClass:"date"},[_vm._v("Nearest planning date: "+_vm._s(_vm.nearestPlanningDate.toLocaleDateString()))]),_c('div',{staticClass:"move__button",on:{"click":_vm.moveToNearestPlanningDate}},[_vm._v("Move")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }