<template>
    <div class='stories-item' v-on:click="show()">
        <div class='item-image'>
            <img v-if="imagePath !== ''">
        </div>
    </div>
</template>

<script>


export default {
    name: 'Item',
    props:{
        imagePath: String,
        show: Function
    },
    methods:{
        getPath(path){
            return path
        },

    },

}
</script>

<style scoped>
.stories-item{
    margin-right: 2vw;
    float: left;
}
.item-image{
    max-width: 60px;
    max-height: 60px;
    width: 7vh;
    height: 7vh;
        border: 1px solid black;
    border-radius: 100px;
}
.item-image img{

    width: 7vh;
    height: 7vh;
    object-fit: cover;
    object-position: 40% 50%;
}
</style>