<template>
    <vue-final-modal
        v-model="showModal"
        @click-outside="close"
    >
        <div id="modal-cont">
            <div @click="close" class="close-btn">
                <span class="iconify" data-icon="ep:close"></span>
            </div>
        </div>
    </vue-final-modal>
</template>

<script>
export default {
    name: 'Modal',
    props: {
        showModal: Boolean,
        close: Function,
    },
    mount(){
        this.show()
    },
    methods:{
        clickOutside(){
            this.showModal = false
        },
    }
}
</script>

<style scoped>
#modal-cont{
    margin: 40px 40px;
    height: 80vh;
    background: white;
}
</style>