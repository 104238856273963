<template>
    <div class="about" 
        v-bind:class="{
            swiped: isSwiped
        }">
        <div v-bind:id="isSwiped ? 'small-about' : 'big-about'">
            <div class="avatar-cont" 
                v-bind:class="{
                    'big': !isSwiped,
                    'small': isSwiped,
                }">
                <img src="@/assets/yoga.jpg" id="avatar" @click="changeMode(!isSwiped)">
                <div class="star-cont"  v-if="!isSwiped" 
                    v-on:click="setFavorite"
                    v-bind:style="{
                        'color': isFavorite? '#F9DC5C':'#969696'
                    }">
                    <span class="iconify" 
                        data-icon="ant-design:star-filled" 
                        data-width="24" 
                        data-height="24"
                    ></span>
                </div>
            </div>
            <div v-bind:class="isSwiped ? 'small-info' : 'big-info'">
                <div class="header__cont">
                    <div class="header__title" @click="changeMode(!isSwiped)">
                        {{calendarRestricted ? calendarRestricted.title : calendarTitle}}
                    </div>
                    <div class="timezone">
                        {{timezone}}
                    </div>
                </div>
                <div class="description" v-if="!isSwiped">
                    {{calendarDescription}}
                </div>
                <div class="social__cont">
                    <div v-for="link,key in calendarSocialLinks" :key="`social_link_${key}`">
                        <a :href="link">
                            <span class="iconify" :data-icon="icons[key]" data-width="20" data-height="20"></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name:'About',
    props:{
        isSwiped: Boolean,
        changeMode: Function,
        imageUrl: {
            type: String,
            default: "@/assets/yoga.jpg"
        },
    },
    model: {
        prop: 'isSwiped',
        event: 'update:isSwiped'
    },
    computed: {
        calendarSocialLinks(){
            return this.$store.getters.calendarSocialLinks
        },
        calendarTitle(){
            return this.$store.getters.calendarTitle
        },
        calendarRestricted() {
            return this.$store.getters.calendarRestricted
        },
        calendarDescription(){

            let decription = this.$store.getters.calendarDescription

            if (!decription && this.isDefaultCalendar){
                decription = "This is your personal calendar."
            }

            return decription
        },
        isDefaultCalendar(){
            let defaultCalendarUid = this.$store.getters.getDefaultCalendar
            let currentCalendarUid = this.$route.params.calendarUid
            return currentCalendarUid == defaultCalendarUid
        },
        timezone(){
            let browserTimezone = this.$store.getters.browserTimezone
            let calendarTimezone = this.$store.getters.getTimezone
            if  (!browserTimezone || !calendarTimezone)
                return ""
            
            if (browserTimezone.id == calendarTimezone.id)
                return ""

            return calendarTimezone.name
        },
    },
    data(){
        return {
            isFavorite: false,
            currentLang: "RU",
            icons: {
                "fb": "logos:facebook", 
                "twt": "logos:twitter", 
                "ins": "skill-icons:instagram", 
                "web": "carbon:earth-filled",
            },
        }
    },
    methods:{
        setFavorite(){
            this.isFavorite = !this.isFavorite
        },
    }
}
</script>

<style scoped>

.about{
    margin: 20px 0 15px 30px; 
    background: white;
}

.swiped{
    position: sticky;
    z-index: 4;
    margin: 0;
    padding: 10px 0 5px 30px;
    top: 0;
}

#small-about{
    display: grid;
    grid-template-columns: calc(55px) calc(100% - 15px - 55px);
    grid-column-gap: 15px;
}
#big-about{
    display: grid;
    grid-template-columns: calc(90px) calc(100% - 15px - 90px);
    grid-column-gap: 15px;
}

.avatar-cont{
    border: 1px solid black;
    border-radius: 100px;
    position: relative;
}

.avatar-cont.big{
    height: 90px;
    width: 90px;
}
#avatar{
    border-radius: 100px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 40% 50%;
}

.avatar-cont.small{
    height: 55px;
    width: 55px;
}

.info{
    max-width: 300px;
    padding-top: 1.3vh;
}
.big-info{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.small-info{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.info div{
    width: 100%;
    margin-top: 5px;
}

.description{
    font-size: 12px;
    width: inherit;
}

.social__cont{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.header__cont{
    display: flex;
    flex-direction: column;
}
.header__title{
    display: flex;
    font-weight: bold;
    align-items: center;
}

.timezone{
    width: fit-content;
}

.star-cont{
    position: absolute;
    top: calc(90px - 12px);
    right: calc( (90px - 24px) / 2);
    height: 24px;

}

.switch{
    border: 1px solid #b6b6b6 ;
    height: fit-content;
    font-size:14px;
    border-radius: 5px;
    width: 46px;
    padding: 0 5px;
    display: grid;
    grid-template-columns: 20px 14px;
    grid-column-gap: 6px;
}
.switch div{
    display: flex;
}

.switch-title{
    height: fit-content;
}
.switch-icon{
    height:14px;
    padding: 1.2px 0;
}
.switch-icon div{
    display: flex;
    align-items: center;
}

.selected-switch {
    position: absolute;
    right: 0;
    top:23.8px;
    border: 1px solid #b6b6b6 ;
    border-radius: 5px;
    height: fit-content;
    font-size:14px;
    padding: 2px 0;
    z-index: 6;
    display: grid;
    grid-row-gap: 5px;
    width: 50px;
    background: white;
    color: grey;
}

.selected-switch div{
    margin-right:auto;
    margin-left:auto;
}

.switch-cont{
    display: flex;
    align-items: center;
    color: grey;
}

</style>