<template>
    <div 
    class="calendar-card"
    v-bind:class="{
        'select': isSelected,
        'not-whole-hour': isNotWholeHour,
        'last-column': isLastColumn,
        'canceled': eventSlot.canceled,
    }"
        v-on:click="clickHandler"    
        v-bind:style="{
            ...getColor(),
            top: this.getPosition() + 'px',
        }"
    >
        <a class="small-info" v-if="!isSelected && eventSlot.place" >
            {{eventSlot.place.current}}/{{eventSlot.place.max}}     
        </a>
        <div class="big-info" v-if="isSelected">
                <div 
                class="title"
                >
                    {{eventSlot.title}}
                </div>
                <div class="info">
                    <div class="trainer">
                        {{eventSlot.trainer || "Alina"}}
                    </div>
                    <div v-if="eventSlot.place">
                        {{eventSlot.place.current}}/{{eventSlot.place.max}}
                    </div>
                    
                </div>
        </div>
    </div>   
</template>

<script>
import router from '../../../router.js'
import {bus} from '../EventBus.js'
export default {
    name: "Card",
    props: {
        index: String,
        eventSlot: Object,
        isSelected: Boolean,
        isLastColumn: Boolean,
        position: Number,
        classification: Object,
        date: Date,
    },
    data(){
        return{
            isNotWholeHour: false,
        }
    },
    beforeMount() {
        // For cards, which minutes not equal to 0
        let minute = this.eventSlot.period.minute()     
        this.isNotWholeHour = minute != 0
    },
    methods: {
        clickHandler(){
            if (this.isSelected && !this.eventSlot.isPast()){
                this.openInfo()
            } else {
                this.selectDay(this.date)
            }
        },
        getPosition(){
            return this.position
        },
        selectDay(date){
            bus.$emit('calendar', 'select-date', {
                date: date
            })
        },
        getColor(){
            let style = { 
                color: undefined,
                background: undefined
            }


            // setup background and font color

            if (this.eventSlot.isPast()){
                style.color = 'grey'
                style.background = '#d4d4d4'
                return style
            }

            if (!this.eventSlot.place){
                style.color = 'black'
                style.background = '#b8e2f2'
                return style
            }

            let freePlaces = this.eventSlot.place.max - this.eventSlot.place.current
            let greenCount = this.classification.green
            let redCount = this.classification.red


            if ( freePlaces >= greenCount){ // is green card, a lot of free places
                style.color = '#72bb53'
                style.background = '#629c44'
            } else if ( freePlaces > redCount){ // is yellow card
                style.color = '#d8c93f'
                style.background = '#fefb64'
            } else if ( freePlaces <= redCount){ // is red card, almost no places
                style.color = '#fe7b4a'
                style.background = '#e61610'
            }

            return style
        },
        openInfo(){
            let freePlaces = 0
            if (this.eventSlot.place){
                let maxPlaces = this.eventSlot.place.max || 0
                let busyPlaces = this.eventSlot.place.current || 0
                freePlaces = maxPlaces - busyPlaces
            }

            router.push({name:'event', params: {
                eventId: this.index,
                title: this.eventSlot.title,
                date: this.date,
                freePlaces: freePlaces
            }})
        },
    },
}
</script>

<style scoped>
.calendar-card{
    position:absolute;
    height: 6vh;
    width: inherit;
    width: calc(100% - 1px);
    cursor: pointer;
    font-size:10.5px;
    border-left: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 18px;
    z-index: 2;
}

div:last-child > .card{
    border: 1px solid gray;
}

.select{
    min-width: 50px;
    display: block;

}
.big-info{
    padding: 4px 3px 4px 3px;
    display: grid;
    height: calc(100% - 8px);
    grid-template-rows: calc(100% - 12.8px) 12.8px;
}
.small-info{
    margin: auto;
}

.whiteTitle{
    color: white;
}
.title{
    color: black;
    text-decoration-color: black;
    font-size:12px;
}
.trainer{
    float: right;
}
.not-whole-hour{
    border: 1px solid gray;
    border-right: 0;
}
.last-column{
    border: 1px solid gray;
}
.canceled{
    text-decoration:line-through;
}

</style>