<template>
    <div class="change-column-count" @click="setCount()"> 
        {{countVariants[countIndex]}} days 
    </div>
</template>

<script>
import  {bus}  from '../EventBus.js'

export default {
    name: 'ChangeColumnCount',
    computed: {
        columnCount(){
            return this.$store.getters.tableHeader.columnCount
        },
        countIndex:{
            get(){
                return this.countVariants.indexOf(this.columnCount)
            },
            set(value){
                bus.$emit('calendar', 'set-column-count', {
                    columnCount: this.countVariants[value]
                })
            }
        }
    },
    data(){
        return {
            countVariants: [7,5,3]
        }
    },
    methods: {
        setCount(){
            if (this.countIndex == this.countVariants.length - 1){
                this.countIndex = 0
            } else {
                this.countIndex += 1
            }

        }
    }
}
</script>

<style scoped>
.change-column-count{
    height:30px;
    width: 52px;

    outline: 1px solid #0075ff;
    border-radius: 8px;
    
    font-size: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
</style>