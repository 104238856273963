<template>
    <div class="goto--today" @click="gotoToday()">
        {{(new Date()).getDate()}}
    </div>
</template>

<script>
import {bus} from '../EventBus.js'

export default {
    name: 'Today',
    methods: {
        gotoToday(){
            let today = new Date()
            today.setHours(0,0,0,0)
            bus.$emit('calendar', 'select-date', {date: today, isSetupToday: true})
        }
    },
}
</script>

<style scoped>
.goto--today{
    color: #0075ff;
    font-weight: 400;
    height: 30px;
    width: 30px;
    outline: 1px solid #0075ff;
    border-radius: 8px; 
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>