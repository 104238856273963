<template>
    <div class='filter-tool'>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Tool',
}
</script>

<style scoped>
.filter-tool{
    width: fit-content;
}
</style>